import * as Sentry from "@sentry/react";
import {
  useQuery as useReactQuery,
  useMutation as useReactMutation,
  useInfiniteQuery as useReactInfiniteQuery,
  QueryClient,
  UseQueryOptions,
  UseMutationOptions,
  DefaultOptions,
  UseInfiniteQueryOptions,
  InfiniteData,
  UseMutationResult as ReactQueryUseMutationResult,
  UseQueryResult as ReactQueryUseQueryResult,
  UseInfiniteQueryResult as ReactQueryUseInfiniteQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import { toast } from "react-toastify";

export type PromiseValue<PromiseType> =
  PromiseType extends PromiseLike<infer Value>
    ? PromiseValue<Value>
    : PromiseType;

const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
    retry: false,
  },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });

export const resetQueryClient = async (key: QueryKey) => {
  await queryClient.resetQueries(key, { exact: true });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ExtractFnReturnType<FnType extends (...args: any[]) => any> =
  PromiseValue<ReturnType<FnType>>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type QueryConfigType<QueryFnType extends (...args: any[]) => any> = Omit<
  UseQueryOptions<ExtractFnReturnType<QueryFnType>>,
  "queryKey" | "queryFn"
>;

export type InfiniteQueryConfigType<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  QueryFnType extends (...args: any[]) => any,
> = Omit<
  UseInfiniteQueryOptions<ExtractFnReturnType<QueryFnType>>,
  "queryKey" | "queryFn"
>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MutationConfigType<MutationFnType extends (...args: any) => any> =
  UseMutationOptions<
    ExtractFnReturnType<MutationFnType>,
    { message: string },
    Parameters<MutationFnType>[0]
  >;

export const useQuery = useReactQuery;
export const useInfiniteQuery = useReactInfiniteQuery;

export type UseMutationResult<
  ApiResponseType,
  ApiRequestType = unknown,
> = ReactQueryUseMutationResult<
  ApiResponseType,
  { message: string },
  ApiRequestType
>;

export function useMutation<ApiResponseType, ApiRequestType>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutationFn: (...args: any[]) => any,
  options: MutationConfigType<typeof mutationFn> = {},
): UseMutationResult<ApiResponseType, ApiRequestType> {
  return useReactMutation(mutationFn, {
    ...options,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error, variables: any, context: unknown) => {
      Sentry.captureException(error);
      toast.error(error.message);
      options.onError?.(error, variables, context);
    },
  });
}

export type UseQueryResult<ApiResponseType> =
  ReactQueryUseQueryResult<ApiResponseType>;

export type UseInfiniteQueryResult<ApiResponseType> =
  ReactQueryUseInfiniteQueryResult<ApiResponseType>;

export type InfiniteDataType<T> = InfiniteData<T>;
