import { pagePathUtils } from "~/utils";

export type MenuType =
  | "Home"
  | "Notifications"
  | "Heart"
  | "ChatbubbleEllipses"
  | "Mail"
  | "Bookmark";

type MenusPropsType = {
  type: MenuType;
  href?: string;
};

type ReturnType = {
  menus: MenusPropsType[];
};

export const useMenus = (): ReturnType => {
  const isManagers = pagePathUtils.isManagerPage();
  const isAnalyticsPlans = pagePathUtils.isAnalyticsPlansPage();
  const menus: MenusPropsType[] = [
    {
      type: "Home",
      href: isManagers
        ? "/managers"
        : isAnalyticsPlans
          ? "/analytics_plans"
          : "/",
    },
    { type: "ChatbubbleEllipses" },
    { type: "Mail" },
    { type: "Notifications" },
    { type: "Heart" },
    { type: "Bookmark" },
  ];
  return { menus };
};
