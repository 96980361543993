import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient, ApiResponseError } from "~/utils";

import { ApiManagersSkillEvaluationTermShowResponseType } from "~/domains";

type ParamsType = {
  employeeId?: string;
};

type RequestDataType = {
  id: string;
  params?: ParamsType;
};

const request = async ({
  id,
  params,
}: RequestDataType): Promise<ApiManagersSkillEvaluationTermShowResponseType> => {
  const response =
    await new ApiClient().get<ApiManagersSkillEvaluationTermShowResponseType>(
      `/api/managers/skill_evaluation_terms/${id}`,
      { ...params },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  id: string;
  params?: ParamsType;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisManagersSkillEvaluationTermsShow = ({
  id,
  params,
  config = {},
}: PropsType): UseQueryResult<ApiManagersSkillEvaluationTermShowResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisManagersSkillEvaluationTermsShow", id, params],
    queryFn: () => request({ id, params }),
    useErrorBoundary: (error) => {
      return error instanceof ApiResponseError && error.status >= 500;
    },
  });
};
