import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersEmployeeSurveyQuestionsMutateResponseType } from "~/domains";

type RequestDataType = {
  employeeSurveyId: string;
  employeeSurveyQuestionId: string;
};

const request = ({
  employeeSurveyId,
  employeeSurveyQuestionId,
}: RequestDataType) => {
  return new ApiClient()
    .post<ApiManagersEmployeeSurveyQuestionsMutateResponseType>(
      `/api/managers/employee_surveys/${employeeSurveyId}/employee_survey_questions/${employeeSurveyQuestionId}/duplicates`,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersEmployeeSurveysEmployeeSurveyQuestionsDuplicatesCreate =
  ({ options = {} }: PropsType = {}): UseMutationResult<
    ApiManagersEmployeeSurveyQuestionsMutateResponseType,
    RequestDataType
  > => {
    return useMutation(request, { ...options });
  };
