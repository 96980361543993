import React, { FC, useState, useEffect, FormEvent, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import Dropzone from "react-dropzone";
import { toast } from "react-toastify";

import {
  useProvidersCurrentEmployee,
  useInput,
  useMultipleDropdown,
  useApisMembersProfilesUpdate,
  useApisMembersProfilesAvatarUpdate,
  useApisMembersProfilesHeaderImageUpdate,
} from "~/hooks";

import {
  FormSubmitButton,
  Icon,
  Form,
  Link,
  BackgroundThumbnailImage,
} from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  LabelWithTextField,
  LabelWithDropDownField,
  LabelWithMultipleDropdownField,
  Loading,
} from "~/components/molecules";

import { ResponseMessageType } from "~/domains";

import {
  FILE_MAX_BYTE_SIZE,
  ACCEPT_IMAGE_FILE_TYPES,
  IMAGE_VALIDATE_MESSAGE,
} from "~/constants/file";

export const MembersSettingProfileShow: FC = () => {
  const navigate = useNavigate();
  const { currentEmployee, refetch } = useProvidersCurrentEmployee();

  const [lastName, setLastNameValue] = useInput("");
  const [firstName, setFirstNameValue] = useInput("");
  const [katakanaLastName, setKatakanaLastNameValue] = useInput("");
  const [katakanaFirstName, setKatakanaFirstNameValue] = useInput("");
  const [jobTitle, setJobTitleValue] = useInput("");
  const [objective, setObjectiveValue] = useInput("");
  const [employeeCode, setEmployeeCode] = useInput("");

  const [headerImage, setHeaderImage] = useState<string>();
  const [avatar, setAvatar] = useState<string>();
  const [dropdown] = useMultipleDropdown();

  const { mutate: profileUpdate } = useApisMembersProfilesUpdate();
  const { mutate: headerImageUpdate } =
    useApisMembersProfilesHeaderImageUpdate();
  const { mutate: avatarUpdate } = useApisMembersProfilesAvatarUpdate();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    profileUpdate(
      {
        body: {
          lastName: lastName.value,
          firstName: firstName.value,
          katakanaLastName: katakanaLastName.value,
          katakanaFirstName: katakanaFirstName.value,
          jobTitle: jobTitle.value,
          objective: objective.value,
        },
      },
      {
        onSuccess: (data: ResponseMessageType) => {
          refetch();
          toast(data.message);
          navigate("/profile");
        },
      },
    );
  };

  const onHandleHeaderImageDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (!file) {
      toast.error(IMAGE_VALIDATE_MESSAGE);
      return;
    }

    headerImageUpdate(
      {
        body: { headerImage: file },
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          setHeaderImage(data.headerImageUrl);
        },
      },
    );
  };

  const onHandleAvatarDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (!file) {
      toast.error(IMAGE_VALIDATE_MESSAGE);
      return;
    }

    avatarUpdate(
      {
        body: { avatar: file },
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          setAvatar(data.avatarUrl);
        },
      },
    );
  };

  const setCurrentEmployeeValues = useCallback(() => {
    if (!currentEmployee) return;

    setLastNameValue(currentEmployee?.lastName);
    setFirstNameValue(currentEmployee?.firstName);
    setKatakanaLastNameValue(currentEmployee?.katakanaLastName);
    setKatakanaFirstNameValue(currentEmployee?.katakanaFirstName);
    setObjectiveValue(currentEmployee?.objective);
    setJobTitleValue(currentEmployee?.jobTitle);
    setHeaderImage(currentEmployee?.headerImageUrl);
    setAvatar(currentEmployee?.avatarUrl);
    setEmployeeCode(currentEmployee?.employeeCode);
  }, [
    currentEmployee,
    setFirstNameValue,
    setJobTitleValue,
    setKatakanaFirstNameValue,
    setKatakanaLastNameValue,
    setLastNameValue,
    setObjectiveValue,
    setEmployeeCode,
  ]);

  const breadCrumbItems = [
    { label: "マイページ", href: "/profile" },
    { label: "編集", href: "/setting/profile" },
  ];

  useEffect(() => {
    setCurrentEmployeeValues();
  }, [currentEmployee, setCurrentEmployeeValues]);

  if (!currentEmployee) return <Loading className="mt-8" />;

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        <div className="relative">
          <Dropzone
            onDrop={onHandleHeaderImageDrop}
            accept={ACCEPT_IMAGE_FILE_TYPES}
            multiple={false}
            maxSize={FILE_MAX_BYTE_SIZE}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                className="bg-gray-800 rounded cursor-pointer"
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <BackgroundThumbnailImage
                  bgImageUrl={headerImage}
                  className="opacity-70"
                />
                <div className="absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center w-fit h-fit">
                  <Icon
                    icon="ioImageOutline"
                    size="2.5rem"
                    color="text-white"
                  />
                  <span className="text-sm text-white w-max">1280 x 720</span>
                </div>
              </div>
            )}
          </Dropzone>
          <Dropzone
            onDrop={onHandleAvatarDrop}
            accept={ACCEPT_IMAGE_FILE_TYPES}
            multiple={false}
            maxSize={FILE_MAX_BYTE_SIZE}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                className="absolute -bottom-16 left-4 rounded-full bg-gray-800 cursor-pointer"
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <div
                  className="bg-contain bg-no-repeat bg-center border-2 rounded-full border-solid border-primary-600 w-32 h-32 opacity-70"
                  style={{ backgroundImage: `url("${avatar}")` }}
                />
                <div className="absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center w-fit h-fit">
                  <Icon
                    icon="ioImageOutline"
                    size="1.5rem"
                    color="text-white"
                  />
                  <span className="text-sm text-white w-max">126 x 126</span>
                </div>
              </div>
            )}
          </Dropzone>
        </div>
        <Form className="mt-20 space-y-6" onSubmit={handleSubmit}>
          <>
            <LabelWithTextField
              labelText="目標"
              type="text"
              value={objective.value}
              name="objective"
              onChange={objective.onChange}
            />
            <LabelWithTextField
              labelText="役職"
              type="text"
              value={jobTitle.value}
              name="jobTitle"
              onChange={jobTitle.onChange}
            />
            <div className="grid grid-cols-2 gap-x-8">
              <LabelWithTextField
                labelText="名前(姓)"
                type="text"
                value={lastName.value}
                name="lastName"
                autoComplete="family-name"
                required
                onChange={lastName.onChange}
              />
              <LabelWithTextField
                labelText="名前(名)"
                type="text"
                value={firstName.value}
                name="firstName"
                autoComplete="given-name"
                required
                onChange={firstName.onChange}
              />
            </div>
            <div className="grid grid-cols-2 gap-x-8">
              <LabelWithTextField
                labelText="カナ(姓)"
                type="text"
                value={katakanaLastName.value}
                name="katakanaLastName"
                autoComplete="family-name"
                onChange={katakanaLastName.onChange}
              />
              <LabelWithTextField
                labelText="カナ(名)"
                type="text"
                value={katakanaFirstName.value}
                name="katakanaFirstName"
                autoComplete="given-name"
                onChange={katakanaFirstName.onChange}
              />
            </div>
            <LabelWithMultipleDropdownField
              labelText="所属：部署"
              name="businessDivisions"
              options={[]}
              isDisabled
              value={dropdown.convertToDropdown(
                currentEmployee.businessDivisions,
              )}
            />
            <LabelWithMultipleDropdownField
              labelText="所属：課"
              name="businessSections"
              options={[]}
              isDisabled
              value={dropdown.convertToDropdown(
                currentEmployee.businessSections,
              )}
            />
            <LabelWithDropDownField
              labelText="権限"
              name="employeeRoleId"
              isDisabled
              value={{
                value: currentEmployee.employeeRole.id.toString(),
                label: currentEmployee.employeeRole.name,
              }}
            />
            <LabelWithTextField
              labelText="社員コード"
              type="text"
              value={employeeCode.value}
              name="employeeCode"
              disabled
              onChange={employeeCode.onChange}
            />
            <FormSubmitButton
              value="保存する"
              color="primary"
              className="mx-auto block"
            />
            <div className="text-center space-y-4">
              <Link
                to="/setting/email"
                className="block text-primary-600 hover:text-primary-800"
              >
                メールアドレスを変更する
              </Link>
              <Link
                to="/setting/password"
                className="block text-primary-600 hover:text-primary-800"
              >
                パスワードを変更する
              </Link>
            </div>
          </>
        </Form>
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
