import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApisMembersRankingEmployeeSurveySubmissionsIndexRequestTypes,
  ApisMembersRankingEmployeeSurveySubmissionsIndexResponseTypes,
} from "~/domains";

type RequestDataType = {
  params: ApisMembersRankingEmployeeSurveySubmissionsIndexRequestTypes;
};

const request = async ({
  params,
}: RequestDataType): Promise<ApisMembersRankingEmployeeSurveySubmissionsIndexResponseTypes> => {
  const response =
    await new ApiClient().get<ApisMembersRankingEmployeeSurveySubmissionsIndexResponseTypes>(
      "/api/members/ranking/employee_survey_submissions",
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params: ApisMembersRankingEmployeeSurveySubmissionsIndexRequestTypes;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersRankingEmployeeSurveySubmissionsIndex = ({
  params,
  config = {},
}: PropsType): UseQueryResult<ApisMembersRankingEmployeeSurveySubmissionsIndexResponseTypes> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisMembersRankingEmployeeSurveySubmissionsIndex", params],
    queryFn: () => request({ params }),
  });
};
