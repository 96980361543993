import React, { FC, CSSProperties } from "react";

import { BsQuestionCircle } from "react-icons/bs";

type PropsType = {
  text: string;
  className?: string;
  textClassName?: string;
  textStyle?: CSSProperties;
  iconClassName?: string;
};

export const IconTooltip: FC<PropsType> = ({
  text,
  className = "",
  textClassName = "",
  textStyle = {},
  iconClassName = "",
}: PropsType) => {
  return (
    <span className={`relative inline-flex items-center group ${className}`}>
      <BsQuestionCircle
        className={`ml-1 text-secondary-400 ${iconClassName}`}
      />
      <span
        className={`
          hidden
          bg-black
          px-2
          py-1
          z-modal
          text-center
          text-white
          absolute
          transition
          rounded-3xl
          pointer-events-none
          opacity-0
          left-full
          ml-2
          before:content-['']
          before:absolute
          before:rotate-90
          before:right-full
          before:top-1/2
          before:-translate-y-1/2
          before:border-4
          before:border-transparent
          before:border-r-black
          group-hover:opacity-75
          group-hover:block
          ${textClassName}
        `}
        style={textStyle}
      >
        {text}
      </span>
    </span>
  );
};
