import React, { FC, MouseEvent } from "react";

import {
  useApisMembersDeliveryNotificationsUpdate,
  useArray,
  useBoolean,
} from "~/hooks";

import { ModalWrapper, ButtonWithIcon } from "~/components/atoms";
import { InfiniteScrollWithMoreButton } from "~/components/molecules";

import { ButtonWithDeliveryNotificationItem } from "./ButtonWithDeliveryNotificationItem";

import { DeliveryEventNotificationHistoryType } from "~/domains";

import { useApisMembersDeliveryNotificationsShow } from "~/hooks/apis/Members/DeliveryNotifications/useApisMembersDeliveryNotificationsShow";

type PropsType = {
  hasUnreadDeliveryNotification: boolean;
  className?: string;
};

export const ButtonWithDeliveryNotifications: FC<PropsType> = ({
  hasUnreadDeliveryNotification,
  className = "",
}: PropsType) => {
  const { isChecked: isNotificationOpen, toggle: toggleNotificationOpen } =
    useBoolean(false);
  const { items, setItems } = useArray<DeliveryEventNotificationHistoryType>();
  const { mutate } = useApisMembersDeliveryNotificationsUpdate();

  const handleToggleNotificationOpen = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    toggleNotificationOpen();
    // NOTE: 閉じている状態から、開いた状態に変更した場合に、既読にする
    !isNotificationOpen &&
      mutate({
        body: {
          postableType: undefined,
        },
      });
  };

  const { fetchNextPage, hasNextPage, isLoading } =
    useApisMembersDeliveryNotificationsShow({
      config: {
        enabled: isNotificationOpen,
        useErrorBoundary: () => {
          return false;
        },
        onSuccess: (res) => {
          setItems(
            res.pages.flatMap(
              (page) => page.deliveryEventNotificationHistories,
            ),
          );
        },
      },
    });

  return (
    <div className={className}>
      <ButtonWithIcon
        srOnlyText="お知らせを開く"
        icon={{
          icon: hasUnreadDeliveryNotification
            ? "ioVolumeLow"
            : "ioVolumeLowOutline",
          size: "2rem",
          color: hasUnreadDeliveryNotification
            ? "text-icon-heart"
            : "text-secondary-600",
          className: "hover:text-icon-heart",
        }}
        onClick={handleToggleNotificationOpen}
        className="block"
      />
      {isNotificationOpen && (
        <ModalWrapper onClose={toggleNotificationOpen} headerTitle="お知らせ">
          <InfiniteScrollWithMoreButton
            itemsLength={items.length}
            nextFetchFunction={fetchNextPage}
            hasMore={hasNextPage}
            isLoading={isLoading}
            noContentMessage="お知らせはありません"
          >
            {items.map((item) => (
              <ButtonWithDeliveryNotificationItem key={item.id} item={item} />
            ))}
          </InfiniteScrollWithMoreButton>
        </ModalWrapper>
      )}
    </div>
  );
};
