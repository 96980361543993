import React, { FC } from "react";

import { endOfMonth, startOfMonth } from "date-fns";

import {
  useApisMembersRankingEmployeeSurveySubmissionsIndex,
  useApisMembersRankingInsightPostsIndex,
  useDateRange,
} from "~/hooks";

import { CollapsibleSection } from "~/components/atoms";
import { RangeDatePicker } from "~/components/molecules";

import { RankingEmployeeSurveySubmissionList } from "./RankingEmployeeSurveySubmissionList";
import { RankingInsightPostList } from "./RankingInsightPostList";

type PropsType = {
  className?: string;
};

const today = new Date();

export const RankingInsightPosts: FC<PropsType> = ({
  className = "",
}: PropsType) => {
  const [
    {
      startDate,
      formattedStartDate,
      endDate,
      formattedEndDate,
      onChange: onChangeDateRange,
    },
  ] = useDateRange([startOfMonth(today), endOfMonth(today)]);

  const { data: insightPostsData, isLoading: insightPostsLoading } =
    useApisMembersRankingInsightPostsIndex({
      params: {
        createdAtFrom: formattedStartDate || "",
        createdAtTo: formattedEndDate || "",
      },
      config: {
        enabled: !!(formattedStartDate && formattedEndDate),
      },
    });

  const {
    data: employeeSurveySubmissionsData,
    isLoading: employeeSurveySubmissionsLoading,
  } = useApisMembersRankingEmployeeSurveySubmissionsIndex({
    params: {
      createdAtFrom: formattedStartDate || "",
      createdAtTo: formattedEndDate || "",
    },
    config: {
      enabled: !!(formattedStartDate && formattedEndDate),
    },
  });

  return (
    <div className={`${className}`}>
      <RangeDatePicker
        startDate={startDate}
        endDate={endDate}
        onChange={onChangeDateRange}
        maxDate={today}
      />
      <div className="mt-9 space-y-9">
        <CollapsibleSection title="リフレクション">
          <RankingInsightPostList
            title="いいね数"
            isLoading={insightPostsLoading}
            insightPosts={insightPostsData?.likedInsightPosts}
          />

          <RankingInsightPostList
            title="ブックマーク数"
            isLoading={insightPostsLoading}
            insightPosts={insightPostsData?.bookmarkedInsightPosts}
          />
        </CollapsibleSection>
        <CollapsibleSection title="チェックアウト">
          <RankingEmployeeSurveySubmissionList
            title="いいね数"
            isLoading={employeeSurveySubmissionsLoading}
            employeeSurveySubmissions={
              employeeSurveySubmissionsData?.likedEmployeeSurveySubmissions
            }
          />
          <RankingEmployeeSurveySubmissionList
            title="ブックマーク数"
            isLoading={employeeSurveySubmissionsLoading}
            employeeSurveySubmissions={
              employeeSurveySubmissionsData?.bookmarkedEmployeeSurveySubmissions
            }
          />
        </CollapsibleSection>
      </div>
    </div>
  );
};
