import React, { FC } from "react";

import { pagePathUtils } from "~/utils";

import { Link } from "~/components/atoms";

import { BreadCrumbItemType } from "~/domains";

type PropsType = {
  breadCrumbItems: BreadCrumbItemType[];
  className?: string;
};

export const BreadCrumbs: FC<PropsType> = ({
  breadCrumbItems,
  className = "",
}: PropsType) => {
  const isManagerPage = pagePathUtils.isManagerPage();
  const withTopBreadCrumbItems = [
    { label: "Top", href: isManagerPage ? "/managers" : "/" },
    ...breadCrumbItems,
  ];
  const itemSize = withTopBreadCrumbItems.length;

  return (
    <ul
      className={`flex truncate items-center text-xs miniTablet:text-lg font-medium ${className}`}
    >
      {withTopBreadCrumbItems.map((item, index) => {
        const isLast = itemSize === index + 1;
        return (
          <li
            key={`${item.label}-${index}`}
            className={`flex items-center ${isLast && "truncate"}`}
          >
            {item.href ? (
              <Link to={item.href} className={`${isLast && "truncate"}`}>
                {item.label}
              </Link>
            ) : (
              <span className={`${isLast && "truncate"}`}>{item.label}</span>
            )}
            {!isLast && (
              <span className="text-secondary-600 text-sm mx-2">・</span>
            )}
          </li>
        );
      })}
    </ul>
  );
};
