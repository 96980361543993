import React, { FC } from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

type PropsType = {
  value: number;
};

export const DoughnutChart: FC<PropsType> = ({ value }) => {
  const options = {
    chart: {
      type: "pie",
      spacing: [0, 0, 0, 0],
    },
    title: {
      text: "",
    },
    subtitle: {
      text: `${value}%`,
      floating: true,
      verticalAlign: "middle",
      align: "center",
      style: {
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    credits: {
      enabled: false,
    },
    crisp: true,
    plotOptions: {
      series: {
        colorByPoint: true,
        type: "pie",
        size: "100%",
        innerSize: "60%",
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "",
        data: [
          { name: "実績", y: value, color: "#0086CC" },
          { name: "残り", y: Math.max(100 - value, 0), color: "#EBF8FD" },
        ],
      },
    ],
  };

  return (
    <div className="w-[150px] h-[150px] flex justify-center items-center overflow-hidden">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
