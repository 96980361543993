// import React, { FC } from "react";

// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Filler,
//   Legend,
// } from "chart.js";
// import { Line } from "react-chartjs-2";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Filler,
//   Legend,
// );

// type PropsType = {
//   title?: string;
//   datasets: {
//     name: string;
//     areaValue: number;
//     lineValue: number;
//   }[];
//   areaLabel: string;
//   lineLabel: string;
// };

// export const LineAndAreaChart: FC<PropsType> = ({
//   title,
//   datasets,
//   areaLabel,
//   lineLabel,
// }) => {
//   const options = {
//     responsive: true,
//     plugins: {
//       legend: {
//         position: "top" as const,
//       },
//       title: {
//         display: !!title,
//         text: title,
//       },
//     },
//   };
//   const data = {
//     labels: datasets.map((item) => item.name),
//     datasets: [
//       {
//         fill: false,
//         label: lineLabel,
//         data: datasets.map((item) => item.lineValue),
//         borderColor: "#FFD740",
//         backgroundColor: "#FFD740",
//         borderWidth: 2,
//       },
//       {
//         fill: true,
//         label: areaLabel,
//         data: datasets.map((item) => item.areaValue),
//         borderColor: "#0086CC",
//         backgroundColor: "#EBF8FD",
//         borderWidth: 2,
//       },
//     ],
//   };

//   return <Line options={options} data={data} />;
// };

import React, { FC } from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// PropsTypeの定義
type PropsType = {
  title?: string;
  datasets: {
    name: string;
    areaValue: number;
    lineValue: number;
  }[];
  areaLabel: string;
  lineLabel: string;
  width?: number | string;
  height?: number;
  className?: string;
};

export const LineAndAreaChart: FC<PropsType> = ({
  title,
  datasets,
  areaLabel,
  lineLabel,
  width,
  height,
  className = "",
}) => {
  const categories = datasets.map((item) => item.name);
  const series = [
    {
      type: "line",
      name: lineLabel,
      data: datasets.map((item) => item.lineValue),
      color: "#FFD740",
      lineWidth: 2,
    },
    {
      type: "area",
      name: areaLabel,
      data: datasets.map((item) => item.areaValue),
      color: "#0086CC",
      fillOpacity: 0.3,
    },
  ];

  const options: Highcharts.Options = {
    chart: {
      type: "line",
      height,
      width,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: title || "",
    },
    xAxis: {
      categories: datasets.length > 0 ? categories : [""],
    },
    yAxis: {
      title: {
        text: "",
      },
      min: datasets.length > 0 ? undefined : 0,
      max: datasets.length > 0 ? undefined : 1,
    },
    plotOptions: {
      series: {
        allowPointSelect: true,
        cursor: "pointer",
      },
    },
    series: series as Highcharts.SeriesOptionsType[],
  };

  return (
    <div className={`min-h-[250px] max-w-full ${className}`}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
