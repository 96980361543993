import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient, ApiResponseError } from "~/utils";

import {
  ApiManagersSkillEvaluationTermProgressesShowResponseType,
  ApiManagersSkillEvaluationTermProgressesShowRequestType,
} from "~/domains";

type RequestDataType = {
  params?: ApiManagersSkillEvaluationTermProgressesShowRequestType;
  skillEvaluationTermId: string;
};

const request = async ({
  params,
  skillEvaluationTermId,
}: RequestDataType): Promise<ApiManagersSkillEvaluationTermProgressesShowResponseType> => {
  const response =
    await new ApiClient().get<ApiManagersSkillEvaluationTermProgressesShowResponseType>(
      `/api/managers/skill_evaluation_terms/${skillEvaluationTermId}/progress`,
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  skillEvaluationTermId: string;
  params?: ApiManagersSkillEvaluationTermProgressesShowRequestType;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisManagersSkillEvaluationTermsProgressesShow = ({
  skillEvaluationTermId,
  params,
  config = {},
}: PropsType): UseQueryResult<ApiManagersSkillEvaluationTermProgressesShowResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [
      "ApisManagersSkillEvaluationTermsResultPointsIndex",
      skillEvaluationTermId,
      params,
    ],
    queryFn: () => request({ skillEvaluationTermId, params }),
    useErrorBoundary: (error) => {
      return error instanceof ApiResponseError && error.status >= 500;
    },
  });
};
