import React, { ChangeEvent, FC, useEffect } from "react";

import { useBoolean } from "~/hooks";

import { Label, MultipleDropDownField } from "~/components/atoms";
import { LabelWithCheckBox } from "~/components/molecules";

import { OptionType, MultiValueType } from "~/domains";

type PropsType = {
  labelText: string;
  required?: boolean;
  name: string;
  options: OptionType[];
  value?: MultiValueType<OptionType>;
  isDisabled?: boolean;
  placeholder?: string;
  creatable?: boolean;
  className?: string;
  tooltipText?: string;
  onCreateOption?: (newValue: string) => void;
  onChange?: (newValue: MultiValueType<OptionType>) => void;
};

export const LabelWithMultipleDropdownField: FC<PropsType> = ({
  labelText,
  required = false,
  name,
  options = [],
  value = [],
  isDisabled = false,
  placeholder = "選択してください",
  className = "",
  onChange,
  onCreateOption,
  creatable = false,
  tooltipText,
}: PropsType) => {
  const { isChecked, onChange: onChangeCheckBox, setFalse } = useBoolean(false);
  const handleChangeCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeCheckBox(e);

    if (!onChange) return;
    e.target.checked ? onChange(options) : onChange([]);
  };
  const allCheckDisplay = !isDisabled && onChange && Boolean(options.length);

  useEffect(() => {
    if (value.length < 1) setFalse();
  }, [setFalse, value.length]);

  return (
    <div className={className}>
      <Label
        htmlFor={name}
        labelText={labelText}
        required={required}
        tooltipText={tooltipText}
        className="block"
      />
      <MultipleDropDownField
        name={name}
        options={options}
        value={value}
        isDisabled={isDisabled}
        placeholder={placeholder}
        onChange={onChange}
        creatable={creatable}
        required={required}
        onCreateOption={onCreateOption}
        className="mt-1.5"
      />
      {allCheckDisplay && (
        <LabelWithCheckBox
          labelText="すべてを選択"
          name={`${name}-checkAll`}
          labelEnd
          checked={isChecked}
          onChange={handleChangeCheckbox}
          className="mt-1"
          labelClassName="text-sm text-secondary-600"
          checkboxClassName="w-4 h-4"
        />
      )}
    </div>
  );
};
