import React, { FC, useState } from "react";

type PropsType = {
  title: string;
  children: React.ReactNode;
  className?: string;
};

export const CollapsibleSection: FC<PropsType> = ({
  title,
  children,
  className = "",
}: PropsType) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const toggleCollapse = () => setIsCollapsed(!isCollapsed);

  return (
    <div className={`${className}`}>
      <div
        className="flex items-center justify-between cursor-pointer bg-gray-100 p-3 rounded-t"
        onClick={toggleCollapse}
      >
        <h2 className="text-xl font-bold">{title}</h2>
        {isCollapsed ? (
          <span className="text-gray-500">▼</span>
        ) : (
          <span className="text-gray-500">▲</span>
        )}
      </div>
      {!isCollapsed && (
        <div className="border border-t-0 rounded-b p-4 space-y-6">
          {children}
        </div>
      )}
    </div>
  );
};
