import React, { FC } from "react";

import {
  useProvidersCurrentEmployee,
  useSkillEvaluationCheckForm,
  useSkillEvaluationTermEmployeeCommentForm,
} from "~/hooks";

import { Button, Form, TextField } from "~/components/atoms";
import {
  CompletedMarkMessage,
  EditAndFormSubmitButton,
  LabelWithTextArea,
  RangeDatePicker,
} from "~/components/molecules";
import { ArchivedSkillEvaluationStandardSummaryCategoryDetail } from "~/components/organisms";

import { SkillEvaluationResultPoints } from "./SkillEvaluationResultPoints";

import {
  EmployeeSelectArchivedSkillEvaluationType,
  ManagerRole,
  MemberRole,
  ArchivedSkillEvaluationStandardSummaryCategoryWithSummariesType,
  SkillEvaluationTermType,
  SkillEvaluationTermResultPointsType,
  SkillEvaluationTermEmployeeCommentType,
  SkillEvaluationTermRevieweeCommentType,
  EmployeeRole,
} from "~/domains";

type PropsType = {
  resultPoints?: Pick<
    SkillEvaluationTermResultPointsType,
    | "totalPoint"
    | "revieweeSumPoint"
    | "reviewerSumPoint"
    | "revieweeAveragePoint"
    | "reviewerAveragePoint"
  >;
  revieweeEmployeeId?: string;
  reviewerEmployeeId?: string;
  skillEvaluationTerm: SkillEvaluationTermType;
  skillEvaluationTermEmployeeComment?: SkillEvaluationTermEmployeeCommentType;
  skillEvaluationTermRevieweeComment?: SkillEvaluationTermRevieweeCommentType;
  archivedSkillEvaluationStandardSummaryCategory: ArchivedSkillEvaluationStandardSummaryCategoryWithSummariesType;
  selectArchivedSkillEvaluationStandards: EmployeeSelectArchivedSkillEvaluationType[];
  employeeRoleType: typeof MemberRole.type | typeof ManagerRole.type;
  disabled?: boolean;
  className?: string;
};

export const SkillEvaluationTermDetail: FC<PropsType> = ({
  resultPoints,
  skillEvaluationTerm,
  skillEvaluationTermEmployeeComment,
  skillEvaluationTermRevieweeComment,
  archivedSkillEvaluationStandardSummaryCategory,
  revieweeEmployeeId = "",
  reviewerEmployeeId,
  selectArchivedSkillEvaluationStandards,
  employeeRoleType,
  disabled = false,
  className = "",
}: PropsType) => {
  const { currentEmployee } = useProvidersCurrentEmployee();

  const {
    isReadOnly,
    handleNotReadonly,
    memberSelectSkillEvaluationStandard,
    memberSave,
    selectedMemberSkillEvaluationStandardIds,
    managerSelectSkillEvaluationStandard,
    managerSave,
    selectedManagerSkillEvaluationStandardIds,
    revieweeDisable,
    reviewerDisable,
    skillEvaluationStandardSummariesCount,
    revieweeComment,
    onChangeRevieweeComment,
  } = useSkillEvaluationCheckForm({
    employeeRoleType,
    skillEvaluationTermId: skillEvaluationTerm.id,
    skillEvaluationStandardSummariesCount:
      archivedSkillEvaluationStandardSummaryCategory
        .archivedSkillEvaluationStandardSummaries.length,
    initialValue: selectArchivedSkillEvaluationStandards,
    skillEvaluationTermRevieweeComment,
    revieweeEmployeeId,
    reviewerEmployeeId,
  });

  const {
    isReadOnly: isCommentReadOnly,
    comment,
    isSubmitting,
    onChangeComment,
    handleNotReadonly: handleCommentNotReadonly,
    handleReadonly: handleCommentReadonly,
    handleDelete: handleCommentDelete,
    handleSave: handleCommentSave,
  } = useSkillEvaluationTermEmployeeCommentForm({
    skillEvaluationTermEmployeeComment,
    skillEvaluationTermId: skillEvaluationTerm.id,
    revieweeEmployeeId,
  });

  const selectSkillEvaluationStandard =
    employeeRoleType === EmployeeRole.MEMBER.type
      ? memberSelectSkillEvaluationStandard
      : managerSelectSkillEvaluationStandard;
  const save =
    employeeRoleType === EmployeeRole.MEMBER.type ? memberSave : managerSave;
  const employeeDisable = () => {
    if (disabled) return disabled;

    return employeeRoleType === EmployeeRole.MEMBER.type
      ? revieweeDisable
      : reviewerDisable;
  };

  const employeeSelectSkillEvaluationStandard = employeeDisable()
    ? undefined
    : selectSkillEvaluationStandard;

  return (
    <div className={`space-y-6 ${className}`}>
      <div className="miniTablet:flex miniTablet:space-x-4 items-center">
        <RangeDatePicker
          readOnly
          className="w-full"
          startDate={new Date(skillEvaluationTerm.fromDate)}
          endDate={new Date(skillEvaluationTerm.toDate)}
        />
        {selectedManagerSkillEvaluationStandardIds.length ===
        skillEvaluationStandardSummariesCount ? (
          <CompletedMarkMessage
            message="管理者チェック済み"
            className="mt-6 miniTablet:mt-0"
          />
        ) : (
          selectArchivedSkillEvaluationStandards.length ===
            skillEvaluationStandardSummariesCount && (
            <CompletedMarkMessage
              message="メンバーチェック済み"
              className="mt-6 miniTablet:mt-0"
            />
          )
        )}
      </div>
      <TextField
        type="text"
        name="title"
        disabled
        value={skillEvaluationTerm.title}
        className="text-center font-semibold"
      />
      {resultPoints && (
        <SkillEvaluationResultPoints
          totalPoint={resultPoints.totalPoint}
          revieweeSumPoint={resultPoints.revieweeSumPoint}
          reviewerSumPoint={resultPoints.reviewerSumPoint}
          revieweeAveragePoint={resultPoints.revieweeAveragePoint}
          reviewerAveragePoint={resultPoints.reviewerAveragePoint}
        />
      )}
      <ArchivedSkillEvaluationStandardSummaryCategoryDetail
        archivedSkillEvaluationStandardSummaryCategory={
          archivedSkillEvaluationStandardSummaryCategory
        }
        memberSelectedSkillEvaluationStandardIds={
          selectedMemberSkillEvaluationStandardIds
        }
        managerSelectedSkillEvaluationStandardIds={
          selectedManagerSkillEvaluationStandardIds
        }
        selectSkillEvaluationStandard={
          isReadOnly ? undefined : employeeSelectSkillEvaluationStandard
        }
      />
      <LabelWithTextArea
        labelText="コメント"
        name="reviweeComment"
        disabled={disabled || currentEmployee?.id !== revieweeEmployeeId}
        value={revieweeComment}
        onChange={onChangeRevieweeComment}
      />
      {Boolean(employeeSelectSkillEvaluationStandard) && (
        <div className="w-full text-right">
          {isReadOnly ? (
            <Button
              text="編集"
              onClick={handleNotReadonly}
              color="gray"
              className="w-full"
              outline
            />
          ) : (
            <Button
              readonly={!employeeSelectSkillEvaluationStandard}
              text="保存"
              color={employeeSelectSkillEvaluationStandard ? "primary" : "gray"}
              onClick={save}
              className="w-full"
            />
          )}
        </div>
      )}
      <Form onSubmit={handleCommentSave}>
        <LabelWithTextArea
          labelText="管理者コメント"
          name="employeeComment"
          disabled={isCommentReadOnly}
          value={comment}
          onChange={onChangeComment}
        />
        {employeeRoleType !== EmployeeRole.MEMBER.type &&
          revieweeEmployeeId && (
            <EditAndFormSubmitButton
              className="mt-6"
              isReadOnly={isCommentReadOnly}
              handleNotReadonly={handleCommentNotReadonly}
              handleReadonly={handleCommentReadonly}
              handleRemove={handleCommentDelete}
              isSubmitting={isSubmitting}
            />
          )}
      </Form>
    </div>
  );
};
