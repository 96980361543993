import { useLocation } from "react-router-dom";

export const pagePathUtils = {
  isStartPathPage: (text: string): boolean => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();
    const regex = new RegExp("^/" + text);
    return regex.test(pathname);
  },

  isManagerPage: (): boolean => {
    return pagePathUtils.isStartPathPage("managers");
  },
  isAnalyticsPlansPage: (): boolean => {
    return pagePathUtils.isStartPathPage("analytics_plans");
  },

  isMembersPage: (): boolean => {
    return (
      !pagePathUtils.isManagerPage() && !pagePathUtils.isAnalyticsPlansPage()
    );
  },
};
