import React, { FC } from "react";

import Highcharts from "highcharts";
import HighChart from "highcharts-react-official";

const generateColors = (count: number) => {
  const baseHue = 206; // #0B85E0に相当する色相
  const baseSaturation = 80; // 彩度 (0B85E0の彩度)
  const baseLightness = 45; // 明度 (0B85E0の明度)

  return Array.from({ length: count }, (_, i) => {
    // 明度を徐々に上げる (max lightness 90%まで)
    const lightness = baseLightness + (i * (90 - baseLightness)) / count;
    return `hsl(${baseHue}, ${baseSaturation}%, ${lightness}%)`;
  });
};

type PropsType = {
  dataSets: {
    label: string;
    value: number;
  }[];
};
export const PieChart: FC<PropsType> = ({ dataSets }) => {
  const colors = generateColors(dataSets.length);
  const options = {
    chart: {
      type: "pie",
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: "",
        data: dataSets.map((dataSet, index) => ({
          name: dataSet.label,
          y: dataSet.value,
          color: colors[index],
        })),
      },
    ],
  };

  return (
    <div className="w-full min-h-[250px]">
      <HighChart highcharts={Highcharts} options={options} />
    </div>
  );
};
