import React, { FC } from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

type PropsType = {
  datasets: {
    name: string;
    lineValue: number;
    barValue: number;
  }[];
  barLabel: string;
  lineLabel: string;
  width?: number | string;
  height?: number;
  className?: string;
};

export const BarAndLineChart: FC<PropsType> = ({
  barLabel,
  lineLabel,
  datasets,
  width,
  height,
  className = "",
}) => {
  const series = [
    {
      type: "line",
      name: lineLabel,
      data: datasets.map((item) => item.lineValue),
      color: "#FFD740",
      lineWidth: 2,
    },
    {
      type: "column",
      name: barLabel,
      data: datasets.map((item) => item.barValue),
      color: "#0086CC",
    },
  ];

  const options: Highcharts.Options = {
    chart: {
      type: "column",
      height,
      width,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: datasets.map((item) => item.name),
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: "",
      },
      min: datasets.length > 0 ? undefined : 0,
      max: datasets.length > 0 ? undefined : 1,
    },
    plotOptions: {
      series: {
        cursor: "pointer",
      },
    },
    series: series as Highcharts.SeriesOptionsType[],
  };

  return (
    <div className={`min-h-[250px] max-w-full ${className}`}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
