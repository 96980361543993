import React, { FC } from "react";

type PropsType = {
  bgImageUrl?: string;
  className?: string;
  height?: number;
  onClick?: () => void;
};

export const BackgroundThumbnailImage: FC<PropsType> = ({
  bgImageUrl = "",
  className = "",
  height = 56.25,
  onClick,
}: PropsType) => {
  return (
    <div
      className={`bg-cover bg-center bg-no-repeat group-hover:opacity-80 ${
        onClick ? "cursor-pointer" : ""
      } ${className}`}
      style={{
        backgroundImage: `url("${bgImageUrl}")`,
        paddingTop: `${height}%`,
      }}
      onClick={onClick}
    />
  );
};
