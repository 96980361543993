import React, { FC } from "react";

import { endOfMonth, startOfMonth } from "date-fns";

import {
  useApisMembersAnalysisDetailOfPostsIndex,
  useDateRange,
} from "~/hooks";

import { CollapsibleSection } from "~/components/atoms";
import { RangeDatePicker } from "~/components/molecules";
import { AnalysisDetailOfPostGraph } from "~/components/organisms";

type PropsType = {
  className?: string;
};

const today = new Date();

export const AnalysisDetailOfPosts: FC<PropsType> = ({
  className = "",
}: PropsType) => {
  const [
    {
      startDate,
      formattedStartDate,
      endDate,
      formattedEndDate,
      onChange: onChangeDateRange,
    },
  ] = useDateRange([startOfMonth(today), endOfMonth(today)]);
  const { data, isLoading } = useApisMembersAnalysisDetailOfPostsIndex({
    params: {
      postDateFrom: formattedStartDate || "",
      postDateTo: formattedEndDate || "",
    },
    config: {
      enabled: !!(formattedStartDate && formattedEndDate),
    },
  });

  return (
    <div className={`${className}`}>
      <RangeDatePicker
        startDate={startDate}
        endDate={endDate}
        onChange={onChangeDateRange}
        maxDate={today}
      />
      <div className="mt-9 space-y-9">
        <CollapsibleSection title="リフレクション">
          <AnalysisDetailOfPostGraph
            title="リフレクション"
            isLoading={isLoading}
            postAnalysis={data?.insightPostAnalysis}
          />
        </CollapsibleSection>
        <CollapsibleSection title="チャレンジ">
          <AnalysisDetailOfPostGraph
            title="チャレンジ"
            isLoading={isLoading}
            postAnalysis={data?.focusChallengeAnalysis}
          />
        </CollapsibleSection>
        <CollapsibleSection title="改善・相談">
          <AnalysisDetailOfPostGraph
            title="改善・相談"
            isLoading={isLoading}
            postAnalysis={data?.kaizenIdeaAnalysis}
          />
        </CollapsibleSection>
        <CollapsibleSection title="リフレクションへのナレッジシェア">
          <AnalysisDetailOfPostGraph
            title="リフレクションへのナレッジシェア"
            isLoading={isLoading}
            postAnalysis={data?.sharedInsightPostAnalysis}
          />
        </CollapsibleSection>
      </div>
    </div>
  );
};
