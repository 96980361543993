import React, { FC, useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";

import { FileRejection } from "react-dropzone/.";
import { toast } from "react-toastify";

import bulkInviteSample from "~/assets/bulk_invite_sample.csv";

import { parseCSV } from "~/utils";

import { useApisManagersBulkInvitesCreate, useBoolean } from "~/hooks";

import {
  Button,
  DownloadLink,
  DropzoneFileFieldWithFile,
  EmployeeCodeRuleMessage,
  LinkButton,
  PasswordRuleMessage,
} from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import { LabelWithCheckBox } from "~/components/molecules";

export const ManagersEmployeeBulkInviteIndex: FC = () => {
  const navigate = useNavigate();

  const [parsedCSV, setParsedCSV] = useState<
    Record<string, string>[] | undefined
  >();
  const { isChecked: isShiftJIS, handleChange: onShiftJisChange } =
    useBoolean(false);

  const [tmpFile, setTmpFile] = useState<File | undefined>(undefined);
  const { mutate: postBulkInvites } = useApisManagersBulkInvitesCreate();

  const handleFileUpload = async (
    acceptedFile: File[],
    fileRejections: FileRejection[],
  ) => {
    if (fileRejections.length) {
      fileRejections.map(({ file, errors }) => {
        if (!errors[0]) return;
        toast.error(`${file.name} - ${errors[0].message}`);
      });
      return;
    }
    const file = acceptedFile[0];
    if (file) {
      setTmpFile(file);
      await handleParseCSV(file, isShiftJIS);
    }
  };

  const handleShiftJISToggle = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!parsedCSV) return;
    if (!tmpFile) return;

    onShiftJisChange(e.currentTarget.checked);
    await handleParseCSV(tmpFile, e.currentTarget.checked);
  };

  const handleParseCSV = async (file: File, isShiftJIS: boolean) => {
    const decoder = isShiftJIS
      ? new TextDecoder("shift-jis")
      : new TextDecoder("utf-8");
    const encodedData = new Uint8Array(await file.arrayBuffer());
    const utf8Text = decoder.decode(encodedData);

    const csvData = parseCSV(utf8Text);
    setParsedCSV(csvData);
  };

  const handleBulkUpload = () => {
    if (!parsedCSV) return;
    const isConfirmed = window.confirm("一括登録を実行しますか？");

    if (!isConfirmed) return;

    const body = parsedCSV.map((row) => ({
      email: row["メールアドレス"] || "",
      lastName: row["名前(姓)"] || "",
      firstName: row["名前(名)"] || "",
      katakanaLastName: row["カナ(姓)"] || "",
      katakanaFirstName: row["カナ(名)"] || "",
      jobTitle: row["職種"] || "",
      employeeRoleJa: row["権限"] || "",
      jobCategoryJa: row["職種"] || "",
      employeeCode: row["ログインID"] || "",
      password: row["初期パスワード"] || "",
      businessDivisions: {
        ja: (row["部署名"] || "").split(",").filter(Boolean),
      },
      businessSections: { ja: (row["課名"] || "").split(",").filter(Boolean) },
    }));

    postBulkInvites(
      { body: body },
      {
        onSuccess: (data) => {
          toast.success(data.message);
          navigate("/managers/employees");
        },
        onError: (error) => {
          toast.error(error.message);
          console.error(error);
        },
      },
    );
  };

  const breadCrumbItems = [
    { label: "ユーザー設定", href: "/managers/employees" },
    { label: "一括登録", href: "/managers/employees" },
  ];

  return (
    <EmployeeLayout withRightSidebar={false} breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar={false}>
        <div className="p-6 space-y-8">
          <div className="flex items-center justify-between">
            {/* ファイル操作ボタン */}
            <DownloadLink
              fileUrl={bulkInviteSample}
              fileName="bulk_invite_sample.csv"
            >
              <Button
                text={"サンプルのCSVをダウンロード"}
                color={"primary"}
                onClick={() => {}}
                outline
              />
            </DownloadLink>
            <div className="flex items-center space-x-4">
              <LinkButton
                text={"非同期Job実行状況を見る"}
                color={"primary"}
                outline
                path="/managers/background_job_statuses"
              />
              <Button
                text={"一括登録を実行する"}
                color={"primary"}
                onClick={handleBulkUpload}
                readonly={!tmpFile}
              />
            </div>
          </div>
          <div className="bg-secondary-100 p-4 rounded-lg">
            <h4 className="text-lg font-semibold text-secondary-900">
              ファイル形式をEXCEL→CSVへ変換する方法
            </h4>
            <ol className="mt-2 list-decimal list-inside space-y-2">
              <li>
                Excelでファイルを開いた状態で「ファイル」→「名前を付けて保存」ボタンを選択
              </li>
              <li>
                「ファイルの種類」を「EXCELブック」→「
                <span className="font-semibold">
                  CSV UTF-8(カンマ区切り)(*.csv)
                </span>
                」に変更
              </li>
            </ol>
          </div>
          {parsedCSV && tmpFile && (
            <div className="mt-4">
              もし文字化けが発生する場合は、以下を試してみてください。
              <LabelWithCheckBox
                labelText="Shift-JISで読み込む"
                name="shift-jis"
                checked={isShiftJIS}
                onChange={handleShiftJISToggle}
              />
            </div>
          )}
          <div className="border-secondary-400 border-t border-b space-y-4">
            <div className="p-4">
              <h4 className="text-lg font-semibold text-secondary-900">
                パスワードについて
              </h4>
              <PasswordRuleMessage className="mt-2" />
            </div>
            <div className="p-4">
              <h4 className="text-lg font-semibold text-secondary-900">
                ログインIDについて
              </h4>
              <EmployeeCodeRuleMessage className="mt-2" />
            </div>
          </div>
          <DropzoneFileFieldWithFile
            label="CSVをアップロード"
            acceptFileTypes={{
              "text/csv": [".csv"],
            }}
            fileClassName={
              "w-full cursor-pointer rounded-lg border-dashed border-2 border-gray-300 text-gray-500 flex justify-center items-center py-2 px-4"
            }
            fileName={tmpFile?.name}
            onDropFiles={handleFileUpload}
            noFilesText={"ファイルが選択されていません"}
          />
          {/* CSV内容の表示 */}
          {parsedCSV && parsedCSV.length > 0 && (
            <div className="overflow-x-auto border border-gray-300 rounded-lg p-4 bg-white shadow-md">
              <table className="min-w-full table-auto">
                <thead>
                  <tr>
                    {Object.keys(parsedCSV[0]!).map((key) => (
                      <th
                        key={key}
                        className="px-4 py-2 text-left text-sm font-medium text-gray-700 bg-gray-100 border-b"
                      >
                        {key}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {parsedCSV.map((row, index) => (
                    <tr
                      key={index}
                      className={`hover:bg-gray-50 ${
                        index % 2 === 0 ? "bg-gray-50" : "bg-white"
                      }`}
                    >
                      {Object.values(row).map((value, i) => (
                        <td
                          key={i}
                          className="px-4 py-2 text-sm text-gray-600 border-b"
                        >
                          {value}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </EmployeeLayoutMainContent>
    </EmployeeLayout>
  );
};
