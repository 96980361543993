import React, {
  ReactNode,
  useState,
  useCallback,
  useMemo,
  useRef,
  FC,
  useEffect,
} from "react";

import {
  BoldButton,
  UnderlineButton,
  HeadlineOneButton,
} from "@draft-js-plugins/buttons";
import DraftJsEditor from "@draft-js-plugins/editor";
import createInlineToolbarPlugin from "@draft-js-plugins/inline-toolbar";
import "@draft-js-plugins/inline-toolbar/lib/plugin.css";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import "draft-js/dist/Draft.css";
import "./EditorStyle.css";
import createMentionPlugin, {
  defaultSuggestionsFilter,
  MentionData,
} from "@draft-js-plugins/mention";
import {
  AtomicBlockUtils,
  ContentBlock,
  DraftHandleValue,
  EditorState,
  RawDraftContentState,
  SelectionState,
} from "draft-js";

import { useApisMembersUploadImagesCreate } from "~/hooks";

import { RequiredHiddenField } from "~/components/atoms";
import { BlockQuoteEditor } from "~/components/organisms";

import { EditorImage } from "./EditorImage";
import { EditorLink } from "./EditorLink";

import { AttachFileType, AvatarAndNameEmployeeType } from "~/domains";

type PropsType = {
  editorState: EditorState;
  readOnly: boolean;
  onChange: (editorState: EditorState) => void;
  mentions?: AvatarAndNameEmployeeType[];
  required?: boolean;
  canFileInText: boolean;
  className?: string;
  defaultAutoFucus?: boolean;
  blockQuote?: {
    content: RawDraftContentState;
    employee: AvatarAndNameEmployeeType;
    files: AttachFileType[];
    createdAt: string;
  };
};

export const Editor: FC<PropsType> = ({
  readOnly,
  editorState,
  onChange,
  mentions = [],
  required = false,
  canFileInText,
  className = "",
  defaultAutoFucus = false,
  blockQuote,
}: PropsType) => {
  const editor = useRef<DraftJsEditor | null>(null);
  const { mutate: uploadRequest } = useApisMembersUploadImagesCreate();
  const [mentionSuggestOpen, setMentionSuggestOpen] = useState(false);
  const [suggestions, setSuggestions] = useState<MentionData[]>(mentions);

  const linkifyPlugin = createLinkifyPlugin({
    component: ({ href, children }: { href: string; children: ReactNode }) => {
      return (
        <EditorLink href={href} readOnly={readOnly}>
          {children}
        </EditorLink>
      );
    },
  });

  const blockRendererFn = useCallback(
    (block: ContentBlock) => {
      if (block.getType() === "atomic") {
        const entityKey = block.getEntityAt(0);
        if (!entityKey) return null;
        const entity = editorState.getCurrentContent().getEntity(entityKey);
        if (!entity) return null;
        if (entity.getType() === "image") {
          const data = entity.getData() as { src: string };
          return {
            component: EditorImage,
            editable: false,
            props: {
              src: data.src,
            },
          };
        }
      }
      return null;
    },
    [editorState],
  );
  const { MentionSuggestions, mentionPlugin } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      mentionPrefix: "@",
      supportWhitespace: true,
      entityMutability: "IMMUTABLE",
    });
    const { MentionSuggestions } = mentionPlugin;

    return { mentionPlugin, MentionSuggestions };
  }, []);

  const onOpenChange = useCallback((open: boolean) => {
    setMentionSuggestOpen(open);
  }, []);

  const onSearchChange = useCallback(
    ({ trigger, value }: { trigger: string; value: string }) => {
      setSuggestions(defaultSuggestionsFilter(value, suggestions, trigger));
    },
    [suggestions],
  );

  const insertImage = useCallback(
    (url: string) => {
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        "image",
        "IMMUTABLE",
        { src: url },
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithEntity,
      });
      return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
    },
    [editorState],
  );

  const [inlineToolbarPlugin, InlineToolbar] = useMemo(() => {
    const inlineToolbarPlugin = createInlineToolbarPlugin();
    return [inlineToolbarPlugin, inlineToolbarPlugin.InlineToolbar];
  }, []);

  const handleDroppedFiles = useCallback(
    (_selection: SelectionState, blobs: Blob[]): DraftHandleValue => {
      if (!blobs[0]) return "not-handled";

      uploadRequest(
        {
          body: { image: blobs[0] },
        },
        {
          onSuccess: (data) => {
            const newState = insertImage(data.imageUrl);
            onChange(newState);
            return "handled";
          },
        },
      );
      return "handled";
    },
    [insertImage, onChange, uploadRequest],
  );
  const plugins = [linkifyPlugin, mentionPlugin, inlineToolbarPlugin].flat();

  const focus = useCallback((): void => {
    editor.current?.focus();
  }, []);

  useEffect(() => {
    defaultAutoFucus && setTimeout(() => focus(), 1);
  }, [defaultAutoFucus, focus]);

  const isTextEmpty =
    editorState.getCurrentContent().getPlainText().length <= 0;
  return (
    <div
      className={`ring-0 ${isTextEmpty && required && "relative"} ${className}`}
      onClick={focus}
    >
      <DraftJsEditor
        readOnly={readOnly}
        editorState={editorState}
        onChange={onChange}
        plugins={plugins}
        blockRendererFn={blockRendererFn}
        handleDroppedFiles={canFileInText ? handleDroppedFiles : undefined}
        ref={(element) => {
          editor.current = element;
        }}
      />
      {blockQuote && (
        <BlockQuoteEditor
          blockQuoteContent={blockQuote.content}
          blockQuoteEmployee={blockQuote.employee}
          blockQuoteFiles={blockQuote.files}
          blockQuoteCreatedAt={blockQuote.createdAt}
          className="mt-2"
        />
      )}
      <InlineToolbar>
        {(externalProps) => (
          <>
            <BoldButton {...externalProps} />
            <UnderlineButton {...externalProps} />
            <HeadlineOneButton {...externalProps} />
          </>
        )}
      </InlineToolbar>
      {Boolean(mentions?.length) && (
        <MentionSuggestions
          open={mentionSuggestOpen}
          onOpenChange={onOpenChange}
          suggestions={suggestions.map((mention) => ({
            ...mention,
            avatar: mention.avatarUrl as string,
          }))}
          onSearchChange={onSearchChange}
        />
      )}
      {required && (
        <RequiredHiddenField
          value={editorState.getCurrentContent().getPlainText()}
        />
      )}
    </div>
  );
};

export type EditorType = DraftJsEditor;
