import { useEffect, useState } from "react";

import { UAParser } from "ua-parser-js";

type BrowserInfo = {
  userAgent?: string;
  browser: {
    name?: string;
    version?: string;
  };
  engine: {
    name?: string;
    version?: string;
  };
  os: {
    name?: string;
    version?: string;
  };
  device: {
    model?: string;
    type?: string;
    vendor?: string;
  };
};

export function useFetchBrowserInfo(): BrowserInfo {
  const [browserInfo, setBrowserInfo] = useState<BrowserInfo>({
    userAgent: "",
    browser: {
      name: "",
      version: "",
    },
    engine: {
      name: "",
      version: "",
    },
    os: {
      name: "",
      version: "",
    },
    device: {
      model: "",
      type: "",
      vendor: "",
    },
  });

  useEffect(() => {
    const parser = new UAParser();
    const result = parser.getResult();
    setBrowserInfo({
      ...result,
      device: {
        ...result.device,
        type: result.device.type || "PC",
      },
      userAgent: result.ua,
    });
  }, []);

  return browserInfo;
}
