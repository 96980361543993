import React, { FC, FormEvent } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useProvidersCurrentEmployee,
  useInput,
  useDropdown,
  useMultiDivisionMultiSectionDropdown,
  useApisManagersEmployeesNew,
  useApisManagersEmployeesInvitesCreate,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { EmployeeNewForm } from "~/components/organisms";

import { ResponseMessageType } from "~/domains";

export const ManagersEmployeesNew: FC = () => {
  const navigate = useNavigate();
  const { currentEmployee } = useProvidersCurrentEmployee();
  const { data: fetchEmployeeNewData } = useApisManagersEmployeesNew();
  const { mutate: postInvite, isLoading: isSubmitting } =
    useApisManagersEmployeesInvitesCreate();

  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
    setSelectedDivisions,
    setSelectedSections,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
  });

  const [email] = useInput("");
  const [lastName] = useInput("");
  const [firstName] = useInput("");
  const [katakanaLastName] = useInput("");
  const [katakanaFirstName] = useInput("");
  const [jobTitle] = useInput("");
  const [employeeCode] = useInput("");
  const [defaultPassword] = useInput("");
  const [employeeRoleDropdown] = useDropdown();
  const [jobCategoryDropdown] = useDropdown();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const body = {
      email: email.value,
      lastName: lastName.value,
      firstName: firstName.value,
      katakanaLastName: katakanaLastName.value,
      katakanaFirstName: katakanaFirstName.value,
      jobTitle: jobTitle.value,
      employeeCode: employeeCode.value,
      password: defaultPassword.value,
      jobCategoryId: jobCategoryDropdown.value?.id.toString() || "",
      employeeRoleId: Number(employeeRoleDropdown.value?.id),
      businessDivisions: {
        ids: selectedDivisions.map((division) => division.id),
      },
      businessSections: {
        ids: optionSelectedSections.map((section) => section.id),
      },
    };
    postInvite(
      {
        body,
      },
      {
        onSuccess: (data: ResponseMessageType) => {
          navigate("/managers/employees");
          toast(data?.message);
        },
      },
    );
  };

  const breadCrumbItems = [
    { label: "ユーザー設定", href: "/managers/employees" },
    { label: "新規作成", href: "/managers/employees/new" },
  ];

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        <EmployeeNewForm
          handleSubmit={handleSubmit}
          isUpdating={isSubmitting}
          isReadonly={false}
          selectableDivisions={selectableDivisions}
          selectedDivisions={selectedDivisions}
          onDivisionChange={onDivisionChange}
          optionSelectableSections={optionSelectableSections}
          optionSelectedSections={optionSelectedSections}
          onSectionChange={onSectionChange}
          jobCategoryOptions={jobCategoryDropdown.convertToDropdown(
            fetchEmployeeNewData?.selectableJobCategories,
          )}
          jobCategoryDropdownValue={jobCategoryDropdown.dropdownValue}
          jobCategoryDropdownOnChange={jobCategoryDropdown.onChange}
          jobTitleValue={jobTitle.value}
          jobTitleOnChange={jobTitle.onChange}
          emailValue={email.value}
          emailOnChange={email.onChange}
          lastNameValue={lastName.value}
          lastNameOnChange={lastName.onChange}
          firstNameValue={firstName.value}
          firstNameOnChange={firstName.onChange}
          katakanaLastNameValue={katakanaLastName.value}
          katakanaLastNameOnChange={katakanaLastName.onChange}
          katakanaFirstNameValue={katakanaFirstName.value}
          katakanaFirstNameOnChange={katakanaFirstName.onChange}
          employeeRoleDropdownValue={employeeRoleDropdown.dropdownValue}
          employeeRoleDropdownOnChange={employeeRoleDropdown.onChange}
          setSelectedDivisions={setSelectedDivisions}
          setSelectedSections={setSelectedSections}
          employeeCodeValue={employeeCode.value}
          employeeCodeOnChange={employeeCode.onChange}
          password={{
            passwordValue: defaultPassword.value,
            passwordOnChange: defaultPassword.onChange,
          }}
          buttonName="新規作成"
        />
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
