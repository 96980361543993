import React, { FC } from "react";
import { Routes, Route } from "react-router-dom";

import {
  AdminOperatorLayoutWrapper,
  EmployeeLayoutWrapper,
  NotRequiredLoginLayout,
  PartnerConsoleLayoutWrapper,
} from "~/components/layouts";
import {
  NotFoundIndex,
  SignInIndex,
  SignUpIndex,
  PasswordResetIndex,
  PasswordEdit,
  BrowserInfoIndex,
  MembersProfilesShow,
  MembersSettingProfileShow,
  MembersSettingPasswordShow,
  MembersSettingEmailShow,
  MembersSettingEmailEdit,
  MembersInsightPostsIndex,
  MembersInsightPostsShow,
  MembersInsightPostCommentsShow,
  MembersKnowledgeInsightPostCommentsShow,
  MembersBookmarkedInsightPostsIndex,
  MembersKnowledgeInsightPostsFromTeamsIndex,
  MembersKnowledgeInsightPostsFromManagersIndex,
  MembersKnowledgeInsightPostsShow,
  MembersBookmarkedKnowledgeInsightPostsIndex,
  MembersBookmarkInsightPostBookmarkFoldersInsightPostsIndex,
  MembersBookmarkKnowledgeInsightPostBookmarkFoldersKnowledgeInsightPostsIndex,
  MembersFocusChallengesIndex,
  MembersFocusChallengesShow,
  MembersFocusChallengeEmployeeRepliesShow,
  MembersFocusChallengeEmployeeEffortsShow,
  MembersKaizenIdeasIndex,
  MembersKaizenIdeasShow,
  MembersKaizenIdeaRepliesShow,
  MembersKpiDailyPerformancesShow,
  MembersKpiTermsIndex,
  MembersTermEndKpiTermsDashboardShow,
  MembersKpiBusinessSectionsDashboardShow,
  MembersDocumentFileCategoriesIndex,
  MembersQuestionAnswerCategoriesIndex,
  MembersPrivateQuestionAnswerCategoriesIndex,
  MembersSalesConceptCategoriesIndex,
  MembersSalesConceptsShow,
  MembersMeetingNotesNew,
  MembersMeetingNotesIndex,
  MembersMeetingNotesEdit,
  ManagersInsightFocusKaizenContentTemplatesIndex,
  ManagersInsightFocusKaizenContentTemplatesNew,
  ManagersInsightFocusKaizenContentTemplatesEdit,
  MembersSkillEvaluationStandardSummaryCategoriesIndex,
  MembersSkillEvaluationStandardSummaryCategoriesShow,
  MembersSkillEvaluationTermsIndex,
  MembersSkillEvaluationTermsShow,
  MembersSkillEvaluationTermsResultIndex,
  MembersSkillEvaluationTermsResultShow,
  ManagersSkillEvaluationTermsProgressShow,
  MembersRoleplayingConditionsIndex,
  MembersRoleplayingConditionsNew,
  MembersRoleplayingConditionsEdit,
  MembersRoleplayingConditionDashboardShow,
  MembersInputEmployeeSurveysIndex,
  MembersInputEmployeeSurveysShow,
  MembersEmployeeSurveySubmissionsIndex,
  MembersEmployeeSurveySubmissionsShow,
  MembersEmployeeSurveySubmissionRepliesShow,
  MembersPrivatePersonalMemosIndex,
  MembersEmployeesDirectMessageRoomsShow,
  ManagersBackgroundJobStatusesIndex,
  ManagersEmployeesNew,
  ManagersEmployeesIndex,
  ManagersEmployeesEdit,
  ManagersEmployeesChangePasswordsShow,
  ManagersEmployeeBulkInviteIndex,
  ManagersBusinessSectionReviewsIndex,
  ManagersBusinessSectionsBusinessSectionCompetencyReviewsIndex,
  ManagersBusinessSectionsBusinessSectionMemoReviewsIndex,
  ManagersEmployeesEmployeeMemoReviewsIndex,
  ManagersEmployeesEmployeeCompetencyReviewsIndex,
  ManagersLeftEmployeesIndex,
  ManagersLeftEmployeesEdit,
  ManagersJobCategoriesIndex,
  ManagersJobCategoriesNew,
  ManagersJobCategoriesEdit,
  ManagersArchivedJobCategoriesIndex,
  ManagersArchivedJobCategoryPhasesIndex,
  ManagersProvidingServicesIndex,
  ManagersProvidingServicesNew,
  ManagersProvidingServicesEdit,
  ManagersArchivedProvidingServicesIndex,
  ManagersBusinessDivisionsIndex,
  ManagersBusinessDivisionsNew,
  ManagersBusinessDivisionsEdit,
  ManagersArchivedBusinessDivisionsIndex,
  ManagersBusinessSectionsIndex,
  ManagersBusinessSectionsNew,
  ManagersBusinessSectionsEdit,
  ManagersArchivedBusinessSectionsIndex,
  ManagersFeedbackInsightPostsIndex,
  ManagersFeedbackFocusChallengesIndex,
  ManagersKnowledgeInsightPostsFromTeamsIndex,
  ManagersKnowledgeInsightPostsFromManagersIndex,
  ManagersFocusChallengesIndex,
  ManagersFocusChallengesNew,
  ManagersFocusChallengesEdit,
  ManagersFeedbackKaizenIdeasIndex,
  ManagersKpiTermsNew,
  ManagersKpiTermsEdit,
  ManagersKpiTermsIndex,
  ManagersKpiTermsDashboardShow,
  ManagersBelongingEmployeesKpiTermsIndex,
  ManagersDocumentFileCategoriesNew,
  ManagersDocumentFileCategoriesEdit,
  ManagersDocumentFileCategoriesIndex,
  ManagersDocumentFileCategoriesDisplayOrderIndex,
  ManagersQuestionAnswerCategoriesIndex,
  ManagersQuestionAnswerCategoriesNew,
  ManagersQuestionAnswerCategoriesEdit,
  ManagersQuestionAnswerCategoriesDisplayOrderIndex,
  ManagersPrivateQuestionAnswersIndex,
  ManagersBelongingEmployeesPrivateQuestionAnswersIndex,
  ManagersSalesConceptCategoriesIndex,
  ManagersSalesConceptCategoriesNew,
  ManagersSalesConceptCategoriesEdit,
  ManagersSalesConceptCategoriesDisplayOrderIndex,
  ManagersMeetingNoteTemplatesNew,
  ManagersMeetingNoteTemplatesEdit,
  ManagersMeetingNoteTemplatesIndex,
  ManagersMeetingNotesNew,
  ManagersMeetingNotesEdit,
  ManagersMeetingNotesIndex,
  ManagersBelongingEmployeesMeetingNotesIndex,
  ManagersRoleplayingConditionTemplatesNew,
  ManagersRoleplayingConditionTemplatesEdit,
  ManagersRoleplayingConditionTemplatesIndex,
  ManagersRoleplayingConditionsIndex,
  ManagersRoleplayingConditionsNew,
  ManagersRoleplayingConditionsEdit,
  ManagersRoleplayingConditionEmployeesDashboardShow,
  ManagersBelongingEmployeesRoleplayingConditionsIndex,
  ManagersSkillEvaluationStandardSummaryCategoriesNew,
  ManagersSkillEvaluationStandardSummaryCategoriesEdit,
  ManagersSkillEvaluationStandardSummaryCategoriesIndex,
  ManagersSkillEvaluationStandardSummaryCategoriesDisplayOrderIndex,
  ManagersSkillEvaluationTermsNew,
  ManagersSkillEvaluationTermsEdit,
  ManagersSkillEvaluationTermsIndex,
  ManagersSkillEvaluationTermsShow,
  ManagersSkillEvaluationTermsResultShow,
  ManagersBelongingEmployeesSkillEvaluationTermsIndex,
  ManagersInsightPostObjectivesIndex,
  ManagersInsightPostObjectivesNew,
  ManagersInsightPostObjectivesEdit,
  ManagersInsightPostObjectivesEmployeeAndInsightPostObjectiveShow,
  ManagersBelongingEmployeesInsightPostObjectivesIndex,
  ManagersEmployeeSurveysNew,
  ManagersEmployeeSurveysIndex,
  ManagersEmployeeSurveysEdit,
  ManagersEmployeeSurveysSummarySubmissionsShow,
  ManagersEmployeeSurveySubmissionsIndex,
  ManagersProfilesShow,
  AnalyticsPlansCumulativeRankingsIndex,
  AnalyticsPlansCumulativeAnalysisIndex,
  AnalyticsPlansDetailAnalysisIndex,
  AnalyticsPlansFeedbackAnalysisIndex,
  AnalyticsPlansSalesConceptCategoriesIndex,
  AnalyticsPlansSkillEvaluationStandardSummaryCategoriesIndex,
  AnalyticsPlansQuestionAnswerCategoriesIndex,
  AnalyticsPlansDocumentFileCategoriesIndex,
  AnalyticsPlansSkillEvaluationTermsIndex,
  AnalyticsPlansKnowledgeInsightPostsIndex,
  AnalyticsPlansKpiTermsIndex,
  AnalyticsPlansMeetingNotesIndex,
  AnalyticsPlansRoleplayingConditionsIndex,
  AnalyticsPlansPostsCalendarsIndex,
  AnalyticsPlansInsightPostObjectivesIndex,
  AdminOperatorsSignInIndex,
  AdminOperatorsCompaniesIndex,
  AdminOperatorsCompaniesShow,
  AdminOperatorsCompaniesNew,
  AdminOperatorsCompaniesEdit,
  AdminOperatorsVersionsIndex,
  AdminOperatorsSettingProfileShow,
  AdminOperatorsSettingPasswordShow,
  AdminOperatorsOperatorsIndex,
  AdminOperatorsOperatorsNew,
  AdminOperatorsOperatorsEdit,
  AdminOperatorsCompaniesEmployeesIndex,
  AdminOperatorsCompaniesBillingPeriodLogsIndex,
  AdminOperatorsCompaniesAvailableEmployeeUpperLimitLogsIndex,
  AdminOperatorsCompaniesContractPlanLogsIndex,
  AdminOperatorsCompaniesCopiesIndex,
  PartnerConsolesSignInIndex,
  PartnerConsolesCompaniesIndex,
  PartnerConsolesSettingProfileShow,
  PartnerConsolesSettingPasswordShow,
  PartnerConsolesAnalyticsCumulativeAnalysisIndex,
  PartnerConsolesAnalyticsCumulativeRankingsIndex,
  PartnerConsolesAnalyticsDetailAnalysisIndex,
  PartnerConsolesAnalyticsDocumentFileCategoriesIndex,
  PartnerConsolesAnalyticsFeedbackAnalysisIndex,
  PartnerConsolesAnalyticsKnowledgeInsightPostsIndex,
  PartnerConsolesAnalyticsKpiTermsIndex,
  PartnerConsolesAnalyticsMeetingNotesIndex,
  PartnerConsolesAnalyticsPostsCalendarsIndex,
  PartnerConsolesAnalyticsQuestionAnswerCategoriesIndex,
  PartnerConsolesAnalyticsRoleplayingConditionsIndex,
  PartnerConsolesAnalyticsSalesConceptCategoriesIndex,
  PartnerConsolesAnalyticsSkillEvaluationStandardSummaryCategoriesIndex,
  PartnerConsolesAnalyticsSkillEvaluationTermsIndex,
  PartnerConsolesAnalyticsInsightPostObjectivesIndex,
} from "~/components/pages";

import {
  AllRoles,
  AnalyticsPlanType,
  ManagerOrOwnerRoles,
  NormalPlanType,
} from "~/domains";

export const AppRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<NotRequiredLoginLayout />}>
        <Route path="/sign_in" element={<SignInIndex />} caseSensitive />
        <Route path="/sign_up/:id" element={<SignUpIndex />} caseSensitive />
        <Route
          path="/password/reset"
          element={<PasswordResetIndex />}
          caseSensitive
        />
        <Route path="/password/edit" element={<PasswordEdit />} caseSensitive />
        <Route
          path="/admin_operators/sign_in"
          element={<AdminOperatorsSignInIndex />}
          caseSensitive
        />
        <Route
          path="/partner_consoles/sign_in"
          element={<PartnerConsolesSignInIndex />}
          caseSensitive
        />
        <Route
          path="/browser_info"
          element={<BrowserInfoIndex />}
          caseSensitive
        />
      </Route>
      <Route element={<AdminOperatorLayoutWrapper />}>
        <Route
          path="/admin_operators"
          element={<AdminOperatorsCompaniesIndex />}
          caseSensitive
        />
        <Route
          path="/admin_operators/companies/:id"
          element={<AdminOperatorsCompaniesShow />}
          caseSensitive
        />
        <Route
          path="/admin_operators/companies/new"
          element={<AdminOperatorsCompaniesNew />}
          caseSensitive
        />
        <Route
          path="/admin_operators/companies/:id/edit"
          element={<AdminOperatorsCompaniesEdit />}
          caseSensitive
        />
        <Route
          path="/admin_operators/companies/:companyId/employees"
          element={<AdminOperatorsCompaniesEmployeesIndex />}
          caseSensitive
        />
        <Route
          path="/admin_operators/companies/:companyId/billing_period_logs"
          element={<AdminOperatorsCompaniesBillingPeriodLogsIndex />}
          caseSensitive
        />
        <Route
          path="/admin_operators/companies/:companyId/available_employee_upper_limit_logs"
          element={
            <AdminOperatorsCompaniesAvailableEmployeeUpperLimitLogsIndex />
          }
          caseSensitive
        />
        <Route
          path="/admin_operators/companies/:companyId/contract_plan_logs"
          element={<AdminOperatorsCompaniesContractPlanLogsIndex />}
          caseSensitive
        />
        <Route
          path="/admin_operators/companies/:companyId/copies"
          element={<AdminOperatorsCompaniesCopiesIndex />}
          caseSensitive
        />
        <Route
          path="/admin_operators/versions"
          element={<AdminOperatorsVersionsIndex />}
          caseSensitive
        />
        <Route
          path="/admin_operators/setting/profile"
          element={<AdminOperatorsSettingProfileShow />}
          caseSensitive
        />
        <Route
          path="/admin_operators/setting/password"
          element={<AdminOperatorsSettingPasswordShow />}
          caseSensitive
        />
        <Route
          path="/admin_operators/operators"
          element={<AdminOperatorsOperatorsIndex />}
          caseSensitive
        />
        <Route
          path="/admin_operators/operators/new"
          element={<AdminOperatorsOperatorsNew />}
          caseSensitive
        />
        <Route
          path="/admin_operators/operators/:id/edit"
          element={<AdminOperatorsOperatorsEdit />}
          caseSensitive
        />
      </Route>
      <Route element={<PartnerConsoleLayoutWrapper />}>
        <Route
          path="/partner_consoles"
          element={<PartnerConsolesCompaniesIndex />}
          caseSensitive
        />
        <Route
          path="/partner_consoles/setting/profile"
          element={<PartnerConsolesSettingProfileShow />}
          caseSensitive
        />
        <Route
          path="/partner_consoles/setting/password"
          element={<PartnerConsolesSettingPasswordShow />}
          caseSensitive
        />
        <Route
          path="/partner_consoles/analytics"
          element={<PartnerConsolesAnalyticsCumulativeRankingsIndex />}
          caseSensitive
        />
        <Route
          path="/partner_consoles/analytics/cumulative_analysis"
          element={<PartnerConsolesAnalyticsCumulativeAnalysisIndex />}
          caseSensitive
        />
        <Route
          path="/partner_consoles/analytics/detail_analysis"
          element={<PartnerConsolesAnalyticsDetailAnalysisIndex />}
          caseSensitive
        />
        <Route
          path="/partner_consoles/analytics/document_file_categories"
          element={<PartnerConsolesAnalyticsDocumentFileCategoriesIndex />}
          caseSensitive
        />
        <Route
          path="/partner_consoles/analytics/feedback_analysis"
          element={<PartnerConsolesAnalyticsFeedbackAnalysisIndex />}
          caseSensitive
        />
        <Route
          path="/partner_consoles/analytics/insight_post_objectives"
          element={<PartnerConsolesAnalyticsInsightPostObjectivesIndex />}
          caseSensitive
        />
        <Route
          path="/partner_consoles/analytics/knowledge_insight_posts"
          element={<PartnerConsolesAnalyticsKnowledgeInsightPostsIndex />}
          caseSensitive
        />
        <Route
          path="/partner_consoles/analytics/kpi_terms"
          element={<PartnerConsolesAnalyticsKpiTermsIndex />}
          caseSensitive
        />
        <Route
          path="/partner_consoles/analytics/meeting_notes"
          element={<PartnerConsolesAnalyticsMeetingNotesIndex />}
          caseSensitive
        />
        <Route
          path="/partner_consoles/analytics/posts_calendars"
          element={<PartnerConsolesAnalyticsPostsCalendarsIndex />}
          caseSensitive
        />
        <Route
          path="/partner_consoles/analytics/question_answer_categories"
          element={<PartnerConsolesAnalyticsQuestionAnswerCategoriesIndex />}
          caseSensitive
        />
        <Route
          path="/partner_consoles/analytics/roleplaying_conditions"
          element={<PartnerConsolesAnalyticsRoleplayingConditionsIndex />}
          caseSensitive
        />
        <Route
          path="/partner_consoles/analytics/sales_concept_categories"
          element={<PartnerConsolesAnalyticsSalesConceptCategoriesIndex />}
          caseSensitive
        />
        <Route
          path="/partner_consoles/analytics/skill_evaluation_standard_summary_categories"
          element={
            <PartnerConsolesAnalyticsSkillEvaluationStandardSummaryCategoriesIndex />
          }
          caseSensitive
        />
        <Route
          path="/partner_consoles/analytics/skill_evaluation_terms"
          element={<PartnerConsolesAnalyticsSkillEvaluationTermsIndex />}
          caseSensitive
        />
      </Route>
      <Route
        element={
          <EmployeeLayoutWrapper
            accessAbleRole={ManagerOrOwnerRoles}
            accessAblePlan={[AnalyticsPlanType]}
          />
        }
      >
        <Route
          path="/analytics_plans"
          element={<AnalyticsPlansCumulativeRankingsIndex />}
          caseSensitive
        />
        <Route
          path="/analytics_plans/cumulative_analysis"
          element={<AnalyticsPlansCumulativeAnalysisIndex />}
          caseSensitive
        />
        <Route
          path="/analytics_plans/detail_analysis"
          element={<AnalyticsPlansDetailAnalysisIndex />}
          caseSensitive
        />
        <Route
          path="/analytics_plans/posts_calendars"
          element={<AnalyticsPlansPostsCalendarsIndex />}
          caseSensitive
        />
        <Route
          path="/analytics_plans/feedback_analysis"
          element={<AnalyticsPlansFeedbackAnalysisIndex />}
          caseSensitive
        />
        <Route
          path="/analytics_plans/insight_post_objectives"
          element={<AnalyticsPlansInsightPostObjectivesIndex />}
          caseSensitive
        />
        <Route
          path="/analytics_plans/knowledge_insight_posts"
          element={<AnalyticsPlansKnowledgeInsightPostsIndex />}
          caseSensitive
        />
        <Route
          path="/analytics_plans/kpi_terms"
          element={<AnalyticsPlansKpiTermsIndex />}
          caseSensitive
        />
        <Route
          path="/analytics_plans/document_file_categories"
          element={<AnalyticsPlansDocumentFileCategoriesIndex />}
          caseSensitive
        />
        <Route
          path="/analytics_plans/roleplaying_conditions"
          element={<AnalyticsPlansRoleplayingConditionsIndex />}
          caseSensitive
        />
        <Route
          path="/analytics_plans/meeting_notes"
          element={<AnalyticsPlansMeetingNotesIndex />}
          caseSensitive
        />
        <Route
          path="/analytics_plans/question_answer_categories"
          element={<AnalyticsPlansQuestionAnswerCategoriesIndex />}
          caseSensitive
        />
        <Route
          path="/analytics_plans/skill_evaluation_terms"
          element={<AnalyticsPlansSkillEvaluationTermsIndex />}
          caseSensitive
        />
        <Route
          path="/analytics_plans/skill_evaluation_standard_summary_categories"
          element={
            <AnalyticsPlansSkillEvaluationStandardSummaryCategoriesIndex />
          }
          caseSensitive
        />
        <Route
          path="/analytics_plans/sales_concept_categories"
          element={<AnalyticsPlansSalesConceptCategoriesIndex />}
          caseSensitive
        />
      </Route>
      <Route
        element={
          <EmployeeLayoutWrapper
            accessAbleRole={ManagerOrOwnerRoles}
            accessAblePlan={[NormalPlanType, AnalyticsPlanType]}
          />
        }
      >
        <Route
          path="/managers/employees/new"
          element={<ManagersEmployeesNew />}
          caseSensitive
        />
        <Route
          path="/managers/employee_bulk_invite"
          element={<ManagersEmployeeBulkInviteIndex />}
          caseSensitive
        />
        <Route
          path="/managers/employees"
          element={<ManagersEmployeesIndex />}
          caseSensitive
        />
        <Route
          path="/managers/employees/:id/edit"
          element={<ManagersEmployeesEdit />}
          caseSensitive
        />
        <Route
          path="/managers/employees/:employeeId/change_passwords"
          element={<ManagersEmployeesChangePasswordsShow />}
          caseSensitive
        />
        <Route
          path="/managers/business_section_reviews"
          element={<ManagersBusinessSectionReviewsIndex />}
          caseSensitive
        />
        <Route
          path="/managers/employees/:employeeId/employee_memo_reviews"
          element={<ManagersEmployeesEmployeeMemoReviewsIndex />}
          caseSensitive
        />
        <Route
          path="/managers/employees/:employeeId/employee_competency_reviews"
          element={<ManagersEmployeesEmployeeCompetencyReviewsIndex />}
          caseSensitive
        />
        <Route
          path="/managers/left_employees"
          element={<ManagersLeftEmployeesIndex />}
          caseSensitive
        />
        <Route
          path="/managers/left_employees/:id/edit"
          element={<ManagersLeftEmployeesEdit />}
          caseSensitive
        />
        <Route
          path="/managers/job_categories/new"
          element={<ManagersJobCategoriesNew />}
          caseSensitive
        />
        <Route
          path="/managers/job_categories"
          element={<ManagersJobCategoriesIndex />}
          caseSensitive
        />
        <Route
          path="/managers/job_categories/:id/edit"
          element={<ManagersJobCategoriesEdit />}
          caseSensitive
        />
        <Route
          path="/managers/archived_job_categories"
          element={<ManagersArchivedJobCategoriesIndex />}
          caseSensitive
        />
        <Route
          path="/managers/archived_job_category_phases"
          element={<ManagersArchivedJobCategoryPhasesIndex />}
          caseSensitive
        />
        <Route
          path="/managers/providing_services/new"
          element={<ManagersProvidingServicesNew />}
          caseSensitive
        />
        <Route
          path="/managers/providing_services"
          element={<ManagersProvidingServicesIndex />}
          caseSensitive
        />
        <Route
          path="/managers/providing_services/:id/edit"
          element={<ManagersProvidingServicesEdit />}
          caseSensitive
        />
        <Route
          path="/managers/archived_providing_services"
          element={<ManagersArchivedProvidingServicesIndex />}
          caseSensitive
        />
        <Route
          path="/managers/business_divisions/new"
          element={<ManagersBusinessDivisionsNew />}
          caseSensitive
        />
        <Route
          path="/managers/business_divisions"
          element={<ManagersBusinessDivisionsIndex />}
          caseSensitive
        />
        <Route
          path="/managers/business_divisions/:id/edit"
          element={<ManagersBusinessDivisionsEdit />}
          caseSensitive
        />
        <Route
          path="/managers/archived_business_divisions"
          element={<ManagersArchivedBusinessDivisionsIndex />}
          caseSensitive
        />
        <Route
          path="/managers/business_sections/new"
          element={<ManagersBusinessSectionsNew />}
          caseSensitive
        />
        <Route
          path="/managers/business_sections"
          element={<ManagersBusinessSectionsIndex />}
          caseSensitive
        />
        <Route
          path="/managers/business_sections/:id/edit"
          element={<ManagersBusinessSectionsEdit />}
          caseSensitive
        />
        <Route
          path="/managers/business_sections/:businessSectionId/business_section_competency_reviews"
          element={
            <ManagersBusinessSectionsBusinessSectionCompetencyReviewsIndex />
          }
          caseSensitive
        />
        <Route
          path="/managers/business_sections/:businessSectionId/business_section_memo_reviews"
          element={<ManagersBusinessSectionsBusinessSectionMemoReviewsIndex />}
          caseSensitive
        />
        <Route
          path="/managers/archived_business_sections"
          element={<ManagersArchivedBusinessSectionsIndex />}
          caseSensitive
        />
        <Route
          path="/managers"
          element={<ManagersFeedbackInsightPostsIndex />}
          caseSensitive
        />
        <Route
          path="/managers/feedback/insight_posts"
          element={<ManagersFeedbackInsightPostsIndex />}
          caseSensitive
        />
        <Route
          path="/managers/feedback/focus_challenges"
          element={<ManagersFeedbackFocusChallengesIndex />}
          caseSensitive
        />
        <Route
          path="/managers/feedback/kaizen_ideas"
          element={<ManagersFeedbackKaizenIdeasIndex />}
          caseSensitive
        />
        <Route
          path="/managers/knowledge_post/team"
          element={<ManagersKnowledgeInsightPostsFromTeamsIndex />}
          caseSensitive
        />
        <Route
          path="/managers/knowledge_post/:employeeRole"
          element={<ManagersKnowledgeInsightPostsFromManagersIndex />}
          caseSensitive
        />
        <Route
          path="/managers/focus_challenges"
          element={<ManagersFocusChallengesIndex />}
          caseSensitive
        />
        <Route
          path="/managers/focus_challenges/new"
          element={<ManagersFocusChallengesNew />}
          caseSensitive
        />
        <Route
          path="/managers/focus_challenges/:id/edit"
          element={<ManagersFocusChallengesEdit />}
          caseSensitive
        />
        <Route
          path="/managers/kpi_terms/new"
          element={<ManagersKpiTermsNew />}
          caseSensitive
        />
        <Route
          path="/managers/kpi_terms/:id/edit"
          element={<ManagersKpiTermsEdit />}
          caseSensitive
        />
        <Route
          path="/managers/kpi_terms"
          element={<ManagersKpiTermsIndex />}
          caseSensitive
        />
        <Route
          path="/managers/kpi_terms/:id/dashboard"
          element={<ManagersKpiTermsDashboardShow />}
          caseSensitive
        />
        <Route
          path="/managers/belonging_employees/kpi_terms"
          element={<ManagersBelongingEmployeesKpiTermsIndex />}
          caseSensitive
        />
        <Route
          path="/managers/document_file_categories"
          element={<ManagersDocumentFileCategoriesIndex />}
          caseSensitive
        />
        <Route
          path="/managers/document_file_categories/new"
          element={<ManagersDocumentFileCategoriesNew />}
          caseSensitive
        />
        <Route
          path="/managers/document_file_categories/:id/edit"
          element={<ManagersDocumentFileCategoriesEdit />}
          caseSensitive
        />
        <Route
          path="/managers/document_file_category/display_orders"
          element={<ManagersDocumentFileCategoriesDisplayOrderIndex />}
          caseSensitive
        />
        <Route
          path="/managers/question_answer_categories"
          element={<ManagersQuestionAnswerCategoriesIndex />}
          caseSensitive
        />
        <Route
          path="/managers/question_answer_categories/new"
          element={<ManagersQuestionAnswerCategoriesNew />}
          caseSensitive
        />
        <Route
          path="/managers/question_answer_categories/:id/edit"
          element={<ManagersQuestionAnswerCategoriesEdit />}
          caseSensitive
        />
        <Route
          path="/managers/question_answer_category/display_orders"
          element={<ManagersQuestionAnswerCategoriesDisplayOrderIndex />}
          caseSensitive
        />
        <Route
          path="/managers/private_question_answers"
          element={<ManagersPrivateQuestionAnswersIndex />}
          caseSensitive
        />
        <Route
          path="/managers/belonging_employees/private_question_answers"
          element={<ManagersBelongingEmployeesPrivateQuestionAnswersIndex />}
          caseSensitive
        />
        <Route
          path="/managers/sales_concept_categories"
          element={<ManagersSalesConceptCategoriesIndex />}
          caseSensitive
        />
        <Route
          path="/managers/sales_concept_categories/new"
          element={<ManagersSalesConceptCategoriesNew />}
          caseSensitive
        />
        <Route
          path="/managers/sales_concept_categories/:id/edit"
          element={<ManagersSalesConceptCategoriesEdit />}
          caseSensitive
        />
        <Route
          path="/managers/sales_concept_category/display_orders"
          element={<ManagersSalesConceptCategoriesDisplayOrderIndex />}
          caseSensitive
        />
        <Route
          path="/managers/meeting_note_templates/new"
          element={<ManagersMeetingNoteTemplatesNew />}
          caseSensitive
        />
        <Route
          path="/managers/meeting_note_templates/:id/edit"
          element={<ManagersMeetingNoteTemplatesEdit />}
          caseSensitive
        />
        <Route
          path="/managers/meeting_note_templates"
          element={<ManagersMeetingNoteTemplatesIndex />}
          caseSensitive
        />
        <Route
          path="/managers/meeting_notes/new"
          element={<ManagersMeetingNotesNew />}
          caseSensitive
        />
        <Route
          path="/managers/meeting_notes/:id/edit"
          element={<ManagersMeetingNotesEdit />}
          caseSensitive
        />
        <Route
          path="/managers/meeting_notes"
          element={<ManagersMeetingNotesIndex />}
          caseSensitive
        />
        <Route
          path="/managers/belonging_employees/meeting_notes"
          element={<ManagersBelongingEmployeesMeetingNotesIndex />}
          caseSensitive
        />
        <Route
          path="/managers/insight_focus_kaizen_content_templates"
          element={<ManagersInsightFocusKaizenContentTemplatesIndex />}
          caseSensitive
        />
        <Route
          path="/managers/insight_focus_kaizen_content_templates/new"
          element={<ManagersInsightFocusKaizenContentTemplatesNew />}
          caseSensitive
        />
        <Route
          path="/managers/insight_focus_kaizen_content_templates/:id/edit"
          element={<ManagersInsightFocusKaizenContentTemplatesEdit />}
          caseSensitive
        />
        <Route
          path="/managers/roleplaying_condition_templates/new"
          element={<ManagersRoleplayingConditionTemplatesNew />}
          caseSensitive
        />
        <Route
          path="/managers/roleplaying_condition_templates/:id/edit"
          element={<ManagersRoleplayingConditionTemplatesEdit />}
          caseSensitive
        />
        <Route
          path="/managers/roleplaying_condition_templates"
          element={<ManagersRoleplayingConditionTemplatesIndex />}
          caseSensitive
        />
        <Route
          path="/managers/roleplaying_condition/employees/dashboard"
          element={<ManagersRoleplayingConditionEmployeesDashboardShow />}
          caseSensitive
        />
        <Route
          path="/managers/roleplaying_conditions"
          element={<ManagersRoleplayingConditionsIndex />}
          caseSensitive
        />
        <Route
          path="/managers/roleplaying_conditions/new"
          element={<ManagersRoleplayingConditionsNew />}
          caseSensitive
        />
        <Route
          path="/managers/roleplaying_conditions/:id/edit"
          element={<ManagersRoleplayingConditionsEdit />}
          caseSensitive
        />
        <Route
          path="/managers/belonging_employees/roleplaying_conditions"
          element={<ManagersBelongingEmployeesRoleplayingConditionsIndex />}
          caseSensitive
        />
        <Route
          path="/managers/skill_evaluation_standard_summary_categories/new"
          element={<ManagersSkillEvaluationStandardSummaryCategoriesNew />}
          caseSensitive
        />
        <Route
          path="/managers/skill_evaluation_standard_summary_categories/:id/edit"
          element={<ManagersSkillEvaluationStandardSummaryCategoriesEdit />}
          caseSensitive
        />
        <Route
          path="/managers/skill_evaluation_standard_summary_categories"
          element={<ManagersSkillEvaluationStandardSummaryCategoriesIndex />}
          caseSensitive
        />
        <Route
          path="/managers/skill_evaluation_standard_summary_category/display_orders"
          element={
            <ManagersSkillEvaluationStandardSummaryCategoriesDisplayOrderIndex />
          }
          caseSensitive
        />
        <Route
          path="/managers/skill_evaluation_terms/new"
          element={<ManagersSkillEvaluationTermsNew />}
          caseSensitive
        />
        <Route
          path="/managers/skill_evaluation_terms/:id/edit"
          element={<ManagersSkillEvaluationTermsEdit />}
          caseSensitive
        />
        <Route
          path="/managers/skill_evaluation_terms"
          element={<ManagersSkillEvaluationTermsIndex />}
          caseSensitive
        />
        <Route
          path="/managers/skill_evaluation_terms/:id"
          element={<ManagersSkillEvaluationTermsShow />}
          caseSensitive
        />
        <Route
          path="/managers/skill_evaluation_terms/:id/results"
          element={<ManagersSkillEvaluationTermsResultShow />}
          caseSensitive
        />
        <Route
          path="/managers/skill_evaluation_terms/:id/progress"
          element={<ManagersSkillEvaluationTermsProgressShow />}
          caseSensitive
        />
        <Route
          path="/managers/belonging_employees/skill_evaluation_terms"
          element={<ManagersBelongingEmployeesSkillEvaluationTermsIndex />}
          caseSensitive
        />
        <Route
          path="/managers/insight_post_objectives"
          element={<ManagersInsightPostObjectivesIndex />}
          caseSensitive
        />
        <Route
          path="/managers/insight_post_objectives/new"
          element={<ManagersInsightPostObjectivesNew />}
          caseSensitive
        />
        <Route
          path="/managers/insight_post_objectives/:id/edit"
          element={<ManagersInsightPostObjectivesEdit />}
          caseSensitive
        />
        <Route
          path="/managers/insight_post_objectives/:insightPostObjectiveId/employee_and_insight_post_objectives"
          element={
            <ManagersInsightPostObjectivesEmployeeAndInsightPostObjectiveShow />
          }
          caseSensitive
        />
        <Route
          path="/managers/belonging_employees/insight_post_objectives"
          element={<ManagersBelongingEmployeesInsightPostObjectivesIndex />}
          caseSensitive
        />
        <Route
          path="/managers/employee_surveys"
          element={<ManagersEmployeeSurveysIndex />}
          caseSensitive
        />
        <Route
          path="/managers/employee_surveys/new"
          element={<ManagersEmployeeSurveysNew />}
          caseSensitive
        />
        <Route
          path="/managers/employee_surveys/:id/edit"
          element={<ManagersEmployeeSurveysEdit />}
          caseSensitive
        />
        <Route
          path="/managers/employee_surveys/:id/summary/submissions"
          element={<ManagersEmployeeSurveysSummarySubmissionsShow />}
          caseSensitive
        />
        <Route
          path="/managers/employee_survey_submissions"
          element={<ManagersEmployeeSurveySubmissionsIndex />}
          caseSensitive
        />
        <Route
          path="/managers/profile"
          element={<ManagersProfilesShow />}
          caseSensitive
        />
        <Route
          path="/managers/background_job_statuses"
          element={<ManagersBackgroundJobStatusesIndex />}
          caseSensitive
        />
      </Route>
      <Route
        element={
          <EmployeeLayoutWrapper
            accessAbleRole={AllRoles}
            accessAblePlan={[NormalPlanType, AnalyticsPlanType]}
          />
        }
      >
        <Route
          path="/profile"
          element={<MembersProfilesShow />}
          caseSensitive
        />
        <Route
          path="/setting/profile"
          element={<MembersSettingProfileShow />}
          caseSensitive
        />
        <Route
          path="/setting/password"
          element={<MembersSettingPasswordShow />}
          caseSensitive
        />
        <Route
          path="/setting/email"
          element={<MembersSettingEmailShow />}
          caseSensitive
        />
        <Route
          path="/setting/email/:id/edit"
          element={<MembersSettingEmailEdit />}
          caseSensitive
        />
        <Route
          path="/insight_posts"
          element={<MembersInsightPostsIndex />}
          caseSensitive
        />
        <Route path="/" element={<MembersInsightPostsIndex />} caseSensitive />
        <Route
          path="/insight_posts/:id"
          element={<MembersInsightPostsShow />}
          caseSensitive
        />
        <Route
          path="/insight_post_comments/:id"
          element={<MembersInsightPostCommentsShow />}
          caseSensitive
        />
        <Route
          path="/knowledge_insight_post_comments/:id"
          element={<MembersKnowledgeInsightPostCommentsShow />}
          caseSensitive
        />
        <Route
          path="/bookmark/insight_posts"
          element={<MembersBookmarkedInsightPostsIndex />}
          caseSensitive
        />
        <Route
          path="/bookmark/insight_post_bookmark_folders/:id"
          element={
            <MembersBookmarkInsightPostBookmarkFoldersInsightPostsIndex />
          }
          caseSensitive
        />
        <Route
          path="/knowledge_insight_posts/:id"
          element={<MembersKnowledgeInsightPostsShow />}
          caseSensitive
        />
        <Route
          path="/bookmark/knowledge_insight_posts"
          element={<MembersBookmarkedKnowledgeInsightPostsIndex />}
          caseSensitive
        />
        <Route
          path="/bookmark/knowledge_insight_post_bookmark_folders/:id"
          element={
            <MembersBookmarkKnowledgeInsightPostBookmarkFoldersKnowledgeInsightPostsIndex />
          }
          caseSensitive
        />
        <Route
          path="/knowledge_post/team"
          element={<MembersKnowledgeInsightPostsFromTeamsIndex />}
          caseSensitive
        />
        <Route
          path="/knowledge_post/:employeeRole"
          element={<MembersKnowledgeInsightPostsFromManagersIndex />}
          caseSensitive
        />
        <Route
          path="/focus_challenges"
          element={<MembersFocusChallengesIndex />}
          caseSensitive
        />
        <Route
          path="/focus_challenges/:id"
          element={<MembersFocusChallengesShow />}
          caseSensitive
        />
        <Route
          path="/focus_challenge_employee_replies/:id"
          element={<MembersFocusChallengeEmployeeRepliesShow />}
          caseSensitive
        />
        <Route
          path="/focus_challenge_employee_efforts/:id"
          element={<MembersFocusChallengeEmployeeEffortsShow />}
          caseSensitive
        />
        <Route
          path="/kaizen_ideas"
          element={<MembersKaizenIdeasIndex />}
          caseSensitive
        />
        <Route
          path="/kaizen_ideas/:id"
          element={<MembersKaizenIdeasShow />}
          caseSensitive
        />
        <Route
          path="/kaizen_idea_replies/:id"
          element={<MembersKaizenIdeaRepliesShow />}
          caseSensitive
        />
        <Route
          path="/document_file_category/:employeeRole"
          element={<MembersDocumentFileCategoriesIndex />}
          caseSensitive
        />
        <Route
          path="/sales_concept_category/:employeeRole"
          element={<MembersSalesConceptCategoriesIndex />}
          caseSensitive
        />
        <Route
          path="/sales_concepts/:id"
          element={<MembersSalesConceptsShow />}
          caseSensitive
        />
        <Route
          path="/question_answer_category/:employeeRole"
          element={<MembersQuestionAnswerCategoriesIndex />}
          caseSensitive
        />
        <Route
          path="/question_answer_category/member"
          element={<MembersPrivateQuestionAnswerCategoriesIndex />}
          caseSensitive
        />
        <Route
          path="/kpi_daily_performances/:date"
          element={<MembersKpiDailyPerformancesShow />}
          caseSensitive
        />
        <Route
          path="/kpi_terms"
          element={<MembersKpiTermsIndex />}
          caseSensitive
        />
        <Route
          path="/term_end_kpi_terms/:id/dashboard"
          element={<MembersTermEndKpiTermsDashboardShow />}
          caseSensitive
        />
        <Route
          path="/kpi_business_section_dashboard"
          element={<MembersKpiBusinessSectionsDashboardShow />}
          caseSensitive
        />
        <Route
          path="/meeting_notes/new"
          element={<MembersMeetingNotesNew />}
          caseSensitive
        />
        <Route
          path="/meeting_notes"
          element={<MembersMeetingNotesIndex />}
          caseSensitive
        />
        <Route
          path="/meeting_notes/:id/edit"
          element={<MembersMeetingNotesEdit />}
          caseSensitive
        />
        <Route
          path="/skill_evaluation_standard_summary_categories"
          element={<MembersSkillEvaluationStandardSummaryCategoriesIndex />}
          caseSensitive
        />
        <Route
          path="/skill_evaluation_standard_summary_categories/:id"
          element={<MembersSkillEvaluationStandardSummaryCategoriesShow />}
          caseSensitive
        />
        <Route
          path="/skill_evaluation_terms"
          element={<MembersSkillEvaluationTermsIndex />}
          caseSensitive
        />
        <Route
          path="/skill_evaluation_terms/:id"
          element={<MembersSkillEvaluationTermsShow />}
          caseSensitive
        />
        <Route
          path="/skill_evaluation_terms/:id/result"
          element={<MembersSkillEvaluationTermsResultShow />}
          caseSensitive
        />
        <Route
          path="/skill_evaluation_term/results"
          element={<MembersSkillEvaluationTermsResultIndex />}
          caseSensitive
        />
        <Route
          path="/roleplaying_conditions"
          element={<MembersRoleplayingConditionsIndex />}
          caseSensitive
        />
        <Route
          path="/roleplaying_conditions/new"
          element={<MembersRoleplayingConditionsNew />}
          caseSensitive
        />
        <Route
          path="/roleplaying_conditions/:id/edit"
          element={<MembersRoleplayingConditionsEdit />}
          caseSensitive
        />
        <Route
          path="/roleplaying_condition/dashboard"
          element={<MembersRoleplayingConditionDashboardShow />}
          caseSensitive
        />
        <Route
          path="/input_employee_surveys"
          element={<MembersInputEmployeeSurveysIndex />}
          caseSensitive
        />
        <Route
          path="/input_employee_surveys/:id"
          element={<MembersInputEmployeeSurveysShow />}
          caseSensitive
        />
        <Route
          path="/employee_survey_submissions"
          element={<MembersEmployeeSurveySubmissionsIndex />}
          caseSensitive
        />
        <Route
          path="/employee_survey_submissions/:id"
          element={<MembersEmployeeSurveySubmissionsShow />}
          caseSensitive
        />
        <Route
          path="/employee_survey_submission_replies/:id"
          element={<MembersEmployeeSurveySubmissionRepliesShow />}
          caseSensitive
        />
        <Route
          path="/private_personal_memos"
          element={<MembersPrivatePersonalMemosIndex />}
          caseSensitive
        />
        <Route
          path="/employees/:employeeId/direct_message_rooms"
          element={<MembersEmployeesDirectMessageRoomsShow />}
          caseSensitive
        />
        <Route path="*" element={<NotFoundIndex />} />
      </Route>
    </Routes>
  );
};
