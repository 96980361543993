import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { formatDateTime } from "~/libs";

import {
  useApisMembersEmployeeSurveysEmployeeSurveySubmissionsCreate,
  useApisMembersEmployeeSurveysShow,
  useApisMembersEmployeeSurveySubmissionsAiResponsesCreate,
  useArray,
  useBoolean,
} from "~/hooks";

import { MyRoutes } from "~/config/Paths";

import {
  CheckBoxType,
  EmployeeSurveyWithQuestionsType,
  FormEmployeeSurveySubmissionAnswerType,
  NotSharedOption,
  RadioButtonType,
  SelectBoxType,
  TextAreaType,
  TextFieldType,
} from "~/domains";

type ReturnType = {
  employeeSurvey: EmployeeSurveyWithQuestionsType | null;
  isError: boolean;
  isCreating: boolean;
  shareable: boolean;
  postDate: Date;
  onChangePostDate: (date: Date) => void;
  onChangeShareable: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeAnswer: (value: string, questionId: string) => void;
  answers: FormEmployeeSurveySubmissionAnswerType[];
  handleSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

type HandleChangeAnswerPropsType = {
  answer: FormEmployeeSurveySubmissionAnswerType | undefined;
  questionId: string;
  value: string;
  label?: string;
};

type PropsType = {
  employeeSurveyId: string;
};

export function useEmployeeSurveySubmissionForm({
  employeeSurveyId,
}: PropsType): ReturnType {
  const navigate = useNavigate();
  const [employeeSurvey, setEmployeeSurvey] =
    useState<EmployeeSurveyWithQuestionsType | null>(null);
  const {
    isChecked: shareable,
    onChange: onChangeShareable,
    handleChange: setShareable,
  } = useBoolean(true);
  const [postDate, setPostDate] = useState<Date>(new Date());
  const { items: answers, setItems: setAnswers } =
    useArray<FormEmployeeSurveySubmissionAnswerType>([]);
  const { mutate: createEmployeeSurveySubmission, isLoading: isCreating } =
    useApisMembersEmployeeSurveysEmployeeSurveySubmissionsCreate();
  const { isError } = useApisMembersEmployeeSurveysShow({
    id: employeeSurveyId,
    config: {
      enabled: !!employeeSurveyId,
      onSuccess: (data) => {
        setEmployeeSurvey(data.employeeSurvey);
        setShareable(
          data.employeeSurvey.answerShareOption.type !== NotSharedOption.type,
        );
      },
    },
  });
  const { mutate: aiResponseMutate } =
    useApisMembersEmployeeSurveySubmissionsAiResponsesCreate();

  // テキストフィールドまたはテキストエリアの変更を処理する関数
  const handleChangeText = ({
    answer,
    questionId,
    value,
  }: HandleChangeAnswerPropsType) => {
    if (answer) {
      // 既存の回答がある場合、回答を更新する
      setAnswers(
        answers.map((a) =>
          a.employeeSurveyQuestionId === questionId
            ? { ...a, answer: value }
            : a,
        ),
      );
    } else {
      // 新しい回答を追加する
      setAnswers([
        ...answers,
        {
          employeeSurveyQuestionId: questionId,
          answer: value,
          employeeSurveyQuestionOptionId: "",
        },
      ]);
    }
  };

  // ラジオボタンの変更を処理する関数
  const handleChangeRadioButton = ({
    answer,
    questionId,
    value,
    label,
  }: HandleChangeAnswerPropsType) => {
    if (answer) {
      // 既存の回答がある場合、選択肢IDと回答を更新する
      setAnswers(
        answers.map((a) =>
          a.employeeSurveyQuestionId === questionId
            ? { ...a, employeeSurveyQuestionOptionId: value, answer: value }
            : a,
        ),
      );
    } else {
      // 新しい回答を追加する
      setAnswers([
        ...answers,
        {
          employeeSurveyQuestionId: questionId,
          answer: label,
          employeeSurveyQuestionOptionId: value,
        },
      ]);
    }
  };

  // チェックボックスの変更を処理する関数
  const handleChangeCheckBox = ({
    answer,
    questionId,
    value,
    label,
  }: HandleChangeAnswerPropsType) => {
    if (answer && answer.employeeSurveyQuestionOptionId === value) {
      // 既存の回答があり、選択肢IDが一致する場合、回答を削除する
      setAnswers(
        answers.filter(
          (a) =>
            !(
              a.employeeSurveyQuestionId === questionId &&
              a.employeeSurveyQuestionOptionId === value
            ),
        ),
      );
    } else {
      // 新しい回答を追加する
      setAnswers([
        ...answers,
        {
          employeeSurveyQuestionId: questionId,
          answer: label,
          employeeSurveyQuestionOptionId: value,
        },
      ]);
    }
  };

  // 質問に対する回答の変更を処理するメイン関数
  const onChangeAnswer = (value: string, questionId: string) => {
    // 該当する質問を見つける
    const question = employeeSurvey?.employeeSurveyQuestions.find(
      (q) => q.id === questionId,
    );

    // 質問が見つからない場合は処理を終了する
    if (!question) return;

    // 選択されたオプションのラベルを見つける
    const label = question.employeeSurveyQuestionOptions?.find(
      (q) => q.id === value,
    )?.option;

    // 既存の回答を見つける
    // チェックボックスの場合は選択肢IDが一致する回答を見つける

    const answer =
      question.questionType.type === CheckBoxType.type
        ? answers.find(
            (a) =>
              a.employeeSurveyQuestionId === questionId &&
              a.employeeSurveyQuestionOptionId === value,
          )
        : answers.find((a) => a.employeeSurveyQuestionId === questionId);

    // 質問のタイプに応じて対応する変更関数を呼び出す
    if (
      question.questionType.type === TextFieldType.type ||
      question.questionType.type === TextAreaType.type
    ) {
      handleChangeText({ answer, questionId, value });
    } else if (
      question.questionType.type === RadioButtonType.type ||
      question.questionType.type === SelectBoxType.type
    ) {
      handleChangeRadioButton({ answer, questionId, value, label });
    } else if (question.questionType.type === CheckBoxType.type) {
      handleChangeCheckBox({ answer, questionId, value, label });
    }
  };

  const handleCreateEmployeeSurveySubmission = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();

    createEmployeeSurveySubmission(
      {
        employeeSurveyId,
        body: {
          shareable,
          postDate: formatDateTime(postDate, "yyyy-MM-dd"),
          employeeSurveySubmissionAnswers: answers,
        },
      },
      {
        onSuccess: (data) => {
          aiResponseMutate({
            employeeSurveySubmissionId: data.employeeSurveySubmissionId,
          });
          toast(data.message);
          navigate(MyRoutes.Members.employeeSurveySubmissionsIndex());
        },
      },
    );
  };

  return {
    handleSubmit: handleCreateEmployeeSurveySubmission,
    isError,
    isCreating,
    employeeSurvey,
    shareable,
    onChangeShareable,
    onChangeAnswer,
    answers,
    postDate,
    onChangePostDate: setPostDate,
  };
}
