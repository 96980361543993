import React, { FC } from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

type PropsType = {
  perforManceData: {
    name: string;
    value: number;
    stackValue?: number;
  }[];
  title?: string;
  width?: number | string;
  height?: number;
  indexAxis?: "x" | "y";
  className?: string;
  onClick?: (index?: { index: number }) => void;
};

export const BarChart: FC<PropsType> = ({
  title,
  perforManceData,
  width,
  height,
  indexAxis = "y",
  className = "",
  onClick,
}) => {
  const isStacked = perforManceData.some(
    (item) => item.stackValue !== undefined,
  );
  const series = [
    {
      name: "実績",
      data: perforManceData.map((item) => item.value),
      color: "#0086CC",
    },
  ];

  if (isStacked) {
    series.push({
      name: "目標",
      data: perforManceData.map((item) => item.stackValue || 0),
      color: "#EBF8FD",
    });
  }

  const options: Highcharts.Options = {
    chart: {
      type: indexAxis === "y" ? "bar" : "column",
      height,
      width,
    },
    title: {
      text: title || "",
    },
    xAxis: {
      categories: perforManceData.map((item) => item.name),
    },
    yAxis: {
      title: {
        text: "",
      },
      min: perforManceData.length > 0 ? undefined : 0,
      // BarChartは5段階評価などで使われることがあるため、dataがある場合はそれを最大値とする
      max:
        perforManceData.length > 0
          ? Math.max(...perforManceData.map((item) => item.value))
          : 1,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataGrouping: {
          enabled: false,
        },
        allowPointSelect: true,
        cursor: "pointer",
        point: {
          events: {
            click: function () {
              onClick && onClick({ index: this.index });
            },
          },
        },
      },
    },
    series: series as Highcharts.SeriesOptionsType[],
  };

  return (
    <div
      className={`min-h-[250px] max-w-full flex justify-self-center ${className}`}
    >
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
