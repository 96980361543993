import React, { FC } from "react";

import { Label, DropDownField } from "~/components/atoms";

import { OptionType, SingleValueType } from "~/domains";

type PropsType = {
  labelText: string;
  required?: boolean;
  name: string;
  options?: OptionType[];
  value?: SingleValueType<OptionType>;
  isDisabled?: boolean;
  placeholder?: string;
  creatable?: boolean;
  className?: string;
  wrapperClass?: string;
  tooltipText?: string;
  onCreateOption?: (newValue: string) => void;
  onChange?: (newValue: SingleValueType<OptionType>) => void;
};

export const LabelWithDropDownField: FC<PropsType> = ({
  labelText,
  required = false,
  name,
  options = [],
  value,
  isDisabled = false,
  placeholder = "選択してください",
  creatable,
  className = "",
  wrapperClass = "",
  onCreateOption,
  onChange,
  tooltipText,
}: PropsType) => {
  return (
    <div className={wrapperClass}>
      <Label
        htmlFor={name}
        labelText={labelText}
        required={required}
        tooltipText={tooltipText}
        className="block"
      />
      <DropDownField
        name={name}
        options={options}
        value={value}
        isDisabled={isDisabled}
        placeholder={placeholder}
        onChange={onChange}
        creatable={creatable}
        handleCreate={onCreateOption}
        required={required}
        className={`mt-1.5 ${className}`}
      />
    </div>
  );
};
