import React, { FormEvent, ChangeEvent, FC } from "react";

import { useBoolean } from "~/hooks";

import {
  BooleanDropDownField,
  Form,
  FormSubmitButton,
} from "~/components/atoms";
import {
  LabelWithTextField,
  LabelWithDropDownField,
  StarRatingField,
} from "~/components/molecules";
import { TextEditor } from "~/components/organisms";

import {
  EditorStateType,
  AvatarAndNameEmployeeType,
  OptionType,
  PreviewWithFileType,
  SingleValueType,
} from "~/domains";

type PropsType = {
  isSubmitting: boolean;
  mentions: AvatarAndNameEmployeeType[];
  files: PreviewWithFileType[];
  onChangeFiles: (files: PreviewWithFileType[]) => void;
  editorState: {
    value: EditorStateType;
    onChange: (editorState: EditorStateType) => void;
  };
  clientCompany: {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  };
  title: {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  };
  providingService: {
    selectableValue: OptionType[];
    selected?: SingleValueType<OptionType>;
    onChange: (newValue: SingleValueType<OptionType>) => void;
  };
  priorityRating: {
    value: number;
    onChange: (value: number) => void;
  };
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  submitButtonLabel?: string;
  className?: string;
};

export const KaizenIdeaForm: FC<PropsType> = ({
  isSubmitting,
  mentions,
  files,
  onChangeFiles,
  editorState,
  providingService,
  priorityRating,
  clientCompany,
  title,
  handleSubmit,
  submitButtonLabel = "作成する",
  className = "",
}: PropsType) => {
  const {
    isChecked: fromClientCompany,
    handleChange: handleChangeFromClientCompany,
  } = useBoolean(Boolean(clientCompany.value));

  const handleCheckChange = (newValue = false) => {
    handleChangeFromClientCompany(newValue);
    if (newValue) {
      clientCompany.onChange({
        target: { value: "" },
      } as ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <Form onSubmit={handleSubmit} className={`space-y-6 ${className}`}>
      <LabelWithDropDownField
        labelText="ジャンル"
        name="providingServiceId"
        options={providingService.selectableValue}
        placeholder="全ジャンル共通(ジャンル選択)"
        value={providingService.selected}
        onChange={providingService.onChange}
      />
      <BooleanDropDownField
        labelText="発信元の選択"
        required
        name="isClientCompany"
        trueLabel="お客様からの発信"
        falseLabel="本人からの発信"
        value={fromClientCompany}
        onChange={handleCheckChange}
      />
      {fromClientCompany && (
        <LabelWithTextField
          labelText="発信元"
          required={fromClientCompany}
          type="text"
          name="clientCompany"
          placeholder="発信元の企業名"
          value={clientCompany.value}
          onChange={clientCompany.onChange}
        />
      )}
      <StarRatingField
        labelText="優先度"
        required
        count={5}
        onChange={priorityRating.onChange}
        value={priorityRating.value}
      />
      <LabelWithTextField
        labelText="タイトル"
        type="text"
        name="businessSection"
        placeholder="改善・相談案のタイトル"
        required
        value={title.value}
        onChange={title.onChange}
      />
      <TextEditor
        labelText="内容"
        required
        mentions={mentions}
        editorState={editorState.value}
        setEditorState={editorState.onChange}
        files={files}
        onChangeFiles={onChangeFiles}
      />
      <FormSubmitButton
        color="primary"
        value={submitButtonLabel}
        isReadOnly={isSubmitting}
        className="w-full"
      />
    </Form>
  );
};
