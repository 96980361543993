import { BrowserAgent } from "@newrelic/browser-agent/loaders/browser-agent";
import * as Sentry from "@sentry/react";
import ReactGA from "react-ga4";

const options = {
  init: {
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { deny_list: ["bam.nr-data.net"] },
  }, // NREUM.init
  info: {
    beacon: "bam.nr-data.net",
    errorBeacon: "bam.nr-data.net",
    licenseKey: process.env.NEWRELIC_BROWSER_LICENSE_KEY || "",
    applicationID: process.env.NEWRELIC_BROWSER_APPLICATION_ID || "",
    sa: 1,
  }, // NREUM.info
  loader_config: {
    accountID: process.env.NEWRELIC_BROWSER_ACCOUNT_ID,
    trustKey: process.env.NEWRELIC_BROWSER_ACCOUNT_ID,
    agentID: process.env.NEWRELIC_BROWSER_APPLICATION_ID,
    licenseKey: process.env.NEWRELIC_BROWSER_LICENSE_KEY || "",
    applicationID: process.env.NEWRELIC_BROWSER_APPLICATION_ID || "",
  }, // NREUM.loader_config
};

const env = process.env.NODE_ENV || "";

export const initializeLogger = (): void => {
  if (env === "production") {
    new BrowserAgent(options);
  }
  if (["staging", "production"].includes(env)) {
    Sentry.init({
      dsn: process.env.SENTRY_DSN,
      integrations: [Sentry.browserTracingIntegration()],
      environment: env,
      replaysSessionSampleRate: 0.1,
      tracesSampleRate: 0.1,
      replaysOnErrorSampleRate: 0.1,
    });
    ReactGA.initialize("G-SW6HD9VY6D");
  }
};
