import React, { FC, ChangeEvent } from "react";

import { RadioButtonField, Label } from "~/components/atoms";

type PropsType = {
  labelText: string;
  name: string;
  value?: string | number;
  checked?: boolean;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  tooltipText?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const LabelWithRadioButton: FC<PropsType> = ({
  labelText,
  name,
  value,
  checked = false,
  required = false,
  disabled = false,
  className = "",
  onChange,
  tooltipText,
}: PropsType) => {
  return (
    <div className="flex items-center">
      <RadioButtonField
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        required={required}
        disabled={disabled}
        className={`${className}`}
      />
      <Label
        htmlFor={name}
        labelText={labelText}
        required={required}
        tooltipText={tooltipText}
        className="block ml-2"
      />
    </div>
  );
};
