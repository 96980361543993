import React, { FC, ChangeEvent } from "react";

import { NumberField, Label } from "~/components/atoms";

import { FormSizes } from "~/constants/buttonAndForm";

type PropsType = {
  labelText: string;
  name: string;
  value: number | string;
  defaultValue?: number;
  autoComplete?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  numberFieldClassName?: string;
  labelClassName?: string;
  max?: number;
  min?: number;
  className?: string;
  size?: keyof typeof FormSizes;
  onlyBottomBorder?: boolean;
  tooltipText?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const LabelWithNumberField: FC<PropsType> = ({
  labelText,
  name,
  value,
  defaultValue,
  autoComplete = "",
  placeholder = "",
  required = false,
  disabled = false,
  numberFieldClassName = "",
  labelClassName = "",
  className = "",
  max,
  min,
  onlyBottomBorder = false,
  size = "sm",
  onChange,
  tooltipText,
}: PropsType) => {
  return (
    <div className={className}>
      <Label
        htmlFor={name}
        labelText={labelText}
        required={required}
        tooltipText={tooltipText}
        className={`block ${labelClassName}`}
      />
      <NumberField
        name={name}
        value={value}
        defaultValue={defaultValue}
        autoComplete={autoComplete}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        max={max}
        min={min}
        size={size}
        onChange={onChange}
        onlyBottomBorder={onlyBottomBorder}
        className={`mt-1.5 ${numberFieldClassName}`}
      />
    </div>
  );
};
