import React, { FC } from "react";

import { InnerTitleCountDisplayBox } from "~/components/atoms";

import { SkillEvaluationTermResultPointsType } from "~/domains";

type PropsType = Pick<
  SkillEvaluationTermResultPointsType,
  | "totalPoint"
  | "revieweeSumPoint"
  | "reviewerSumPoint"
  | "revieweeAveragePoint"
  | "reviewerAveragePoint"
> & {
  className?: string;
};

export const SkillEvaluationResultPoints: FC<PropsType> = ({
  totalPoint,
  revieweeSumPoint,
  reviewerSumPoint,
  revieweeAveragePoint,
  reviewerAveragePoint,
  className = "",
}: PropsType) => {
  return (
    <div
      className={`grid grid-cols-2 miniTablet:grid-cols-4 gap-x-6 gap-y-2 ${className}`}
    >
      <InnerTitleCountDisplayBox
        totalPoint={totalPoint}
        point={revieweeSumPoint}
        title="個人チェック"
      />
      <InnerTitleCountDisplayBox
        totalPoint={totalPoint}
        point={reviewerSumPoint}
        title="個人評価"
      />
      <InnerTitleCountDisplayBox
        totalPoint={totalPoint}
        point={revieweeAveragePoint}
        title="平均チェック"
      />
      <InnerTitleCountDisplayBox
        totalPoint={totalPoint}
        point={reviewerAveragePoint}
        title="平均評価"
      />
    </div>
  );
};
