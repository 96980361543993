import React, { FC } from "react";

import { NoContentMessage } from "~/components/atoms";
import { Loading } from "~/components/molecules";
import { EmployeeSurveySubmissionDetail } from "~/components/organisms";

import { EmployeeSurveySubmissionType } from "~/domains";

type PropsType = {
  title: string;
  isLoading: boolean;
  employeeSurveySubmissions?: EmployeeSurveySubmissionType[];
  className?: string;
};

export const RankingEmployeeSurveySubmissionList: FC<PropsType> = ({
  title,
  isLoading,
  employeeSurveySubmissions = [],
  className = "",
}: PropsType) => {
  return (
    <div className={className}>
      <h3 className="text-lg font-bold text-center">{title}</h3>
      {isLoading ? (
        <Loading />
      ) : employeeSurveySubmissions.length ? (
        <div className="mt-6 space-y-4">
          {employeeSurveySubmissions.map((post, index) => (
            <div key={post.id}>
              <span className="text-primary-600 font-black text-lg">
                {index + 1}.
              </span>
              <EmployeeSurveySubmissionDetail
                employeeSurveySubmission={post}
                openReply={() => undefined}
                canDelete={false}
              />
            </div>
          ))}
        </div>
      ) : (
        <NoContentMessage text="データがありません" className="mt-4" />
      )}
    </div>
  );
};
