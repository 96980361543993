import React, { FC } from "react";

import Highcharts from "highcharts";
import HighChart from "highcharts-react-official";

type PropsType = {
  title?: string;
  datasets: {
    name: string;
    value: number;
  }[];
  className?: string;
};

export const AreaChart: FC<PropsType> = ({
  title,
  datasets,
  className = "",
}: PropsType) => {
  console.log({ datasets });
  const options = {
    chart: {
      type: "area",
    },
    title: {
      text: title,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      area: {
        fillOpacity: 0.5,
        allowPointSelect: true,
      },
    },
    xAxis: {
      type: "datetime",
      allowDecimals: false,
    },
    yAxis: {
      title: {
        text: "",
      },
      min: datasets.length > 0 ? undefined : 0,
      max: datasets.length > 0 ? undefined : 1,
    },
    series: [
      {
        name: "実績",
        data: datasets.map((item) => ({
          name: item.name,
          y: item.value,
          x: new Date(item.name),
        })),
      },
    ],
  };

  return (
    <div className={`min-h-[250px] max-w-full ${className}`}>
      <HighChart highcharts={Highcharts} options={options} />
    </div>
  );
};
