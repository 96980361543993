import React, { FC, ReactNode } from "react";

import { UnreadMark, Avatar, Link } from "~/components/atoms";

import {
  DeliveryEventNotificationHistoryPostableTypeColorMapping,
  DeliveryEventNotificationHistoryType,
} from "~/domains";

type WrapperPropsType = {
  item: DeliveryEventNotificationHistoryType;
  className?: string;
  children: ReactNode;
};

export const ButtonWithDeliveryNotificationItemWrapper: FC<
  WrapperPropsType
> = ({ item, className = "", children }: WrapperPropsType) => {
  const postable =
    DeliveryEventNotificationHistoryPostableTypeColorMapping[item.postableType];
  return postable.link ? (
    <Link
      key={item.id}
      to={`${postable.link}/${item.postableId}`}
      className={`flex py-4 space-x-4 border-b border-secondary-400 hover:bg-secondary-100 ${className}`}
    >
      {children}
    </Link>
  ) : (
    <div
      key={item.id}
      className={`flex py-4 space-x-4 border-b border-secondary-400 hover:bg-secondary-100 ${className}`}
    >
      {children}
    </div>
  );
};

type PropsType = {
  item: DeliveryEventNotificationHistoryType;
  className?: string;
};
export const ButtonWithDeliveryNotificationItem: FC<PropsType> = ({
  item,
  className = "",
}: PropsType) => {
  const postable =
    DeliveryEventNotificationHistoryPostableTypeColorMapping[item.postableType];
  return (
    <ButtonWithDeliveryNotificationItemWrapper
      item={item}
      className={className}
    >
      <div className="shrink-0 rounded mt-1.5 relative">
        <Avatar
          src={item.sender.avatarUrl}
          className={postable.className}
          size={"md"}
        />
        {!item.readAt && <UnreadMark />}
      </div>
      <div className="">
        <span className="block truncate font-semibold text-lg">
          {item.jaPostableTypeName}
        </span>
        <p className="line-clamp-2">{item.title}</p>
        <span className="block text-secondary-600 text-sm mt-1">
          {item.createdAt}
        </span>
      </div>
    </ButtonWithDeliveryNotificationItemWrapper>
  );
};
