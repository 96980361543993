import React, { FC } from "react";

type PropsType = {
  className?: string;
};

export const EmployeeCodeRuleMessage: FC<PropsType> = ({
  className = "",
}: PropsType) => {
  return (
    <ul className={`text-secondary-600 text-sm space-y-1 ${className}`}>
      <li>半角英数字のみ4文字以上である必要があります</li>
      <li>他のユーザーと重複しないようにしてください</li>
    </ul>
  );
};
