import React, { FC, ChangeEvent } from "react";

import { CheckBox, Label } from "~/components/atoms";

type PropsType = {
  labelText: string;
  labelClassName?: string;
  checkboxClassName?: string;
  name: string;
  value?: string | number;
  checked?: boolean;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  labelEnd?: boolean;
  tooltipText?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const LabelWithCheckBox: FC<PropsType> = ({
  labelText,
  labelClassName = "",
  checkboxClassName = "",
  name,
  value,
  checked = false,
  required = false,
  disabled = false,
  className = "",
  labelEnd = false,
  onChange,
  tooltipText,
}: PropsType) => {
  return (
    <div className={`flex items-center ${className}`}>
      {!labelEnd && (
        <Label
          htmlFor={name}
          labelText={labelText}
          required={required}
          tooltipText={tooltipText}
          className={`block  mr-2 ${labelClassName}`}
        />
      )}
      <CheckBox
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        required={required}
        disabled={disabled}
        className={checkboxClassName}
      />
      {labelEnd && (
        <Label
          htmlFor={name}
          labelText={labelText}
          required={required}
          tooltipText={tooltipText}
          className={`block ml-2 ${labelClassName}`}
        />
      )}
    </div>
  );
};
