import React, { FC, FormEvent, ChangeEvent, useEffect } from "react";

import { useDropdown, useProvidersCurrentEmployee } from "~/hooks";

import {
  FormSubmitButton,
  Form,
  PasswordRuleMessage,
  EmployeeCodeRuleMessage,
} from "~/components/atoms";
import {
  LabelWithTextField,
  LabelWithDropDownField,
  DisplayablePasswordFiled,
} from "~/components/molecules";
import { MultiParentsWithMultiChildrenDropdownField } from "~/components/organisms";

import {
  BusinessDivisionType,
  BusinessSectionType,
  ChildType,
  EmployeeRole,
  OptionType,
  ParentType,
  SingleValueType,
} from "~/domains";

type PropsType = {
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  isUpdating: boolean;
  isReadonly: boolean;
  selectableDivisions: BusinessDivisionType[];
  selectedDivisions: BusinessDivisionType[];
  onDivisionChange: (newValue: ParentType[]) => void;
  setSelectedDivisions: (newValue: BusinessDivisionType[]) => void;
  optionSelectableSections: ChildType[];
  optionSelectedSections: ChildType[];
  onSectionChange: (newValue: ChildType[]) => void;
  setSelectedSections: (newValue: BusinessSectionType[]) => void;
  jobCategoryOptions?: OptionType[];
  jobCategoryDropdownValue?: SingleValueType<OptionType>;
  jobCategoryDropdownOnChange: (newValue: SingleValueType<OptionType>) => void;
  jobTitleValue?: string;
  jobTitleOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
  emailValue?: string;
  emailOnChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  lastNameValue?: string;
  lastNameOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
  firstNameValue?: string;
  firstNameOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
  katakanaLastNameValue?: string;
  katakanaLastNameOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
  katakanaFirstNameValue?: string;
  katakanaFirstNameOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
  employeeCodeValue?: string;
  employeeCodeOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
  employeeRoleDropdownValue?: SingleValueType<OptionType>;
  employeeRoleDropdownOnChange: (newValue: SingleValueType<OptionType>) => void;
  buttonName: string;
  password: {
    passwordValue: string;
    passwordOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
  };
};

export const EmployeeNewForm: FC<PropsType> = ({
  handleSubmit,
  isUpdating,
  isReadonly,
  selectableDivisions,
  selectedDivisions,
  onDivisionChange,
  optionSelectableSections,
  optionSelectedSections,
  onSectionChange,
  setSelectedDivisions,
  setSelectedSections,
  jobCategoryOptions,
  jobCategoryDropdownValue,
  jobCategoryDropdownOnChange,
  jobTitleValue,
  jobTitleOnChange,
  emailValue,
  emailOnChange,
  lastNameValue,
  lastNameOnChange,
  firstNameValue,
  firstNameOnChange,
  katakanaLastNameValue,
  katakanaLastNameOnChange,
  katakanaFirstNameValue,
  katakanaFirstNameOnChange,
  employeeRoleDropdownValue,
  employeeRoleDropdownOnChange,
  employeeCodeValue,
  employeeCodeOnChange,
  password,
  buttonName,
}: PropsType) => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const [
    {
      dropdownValue: isMailNotifyMode,
      onChange: onChangeMailNotifyMode,
      dropdownSelectableValue: selectableMailNotifyModes,
    },
  ] = useDropdown({
    initialValue: {
      id: "true",
      name: "通知する",
    },
    selectableValue: [
      {
        id: "true",
        name: "通知する",
      },
      {
        id: "false",
        name: "通知しない",
      },
    ],
  });
  const EmployeeRoleOptions =
    currentEmployee?.employeeRole.type === EmployeeRole.OWNER.type
      ? [
          {
            label: EmployeeRole.MEMBER.name,
            value: EmployeeRole.MEMBER.id.toString(),
          },
          {
            label: EmployeeRole.MANAGER.name,
            value: EmployeeRole.MANAGER.id.toString(),
          },
          {
            label: EmployeeRole.OWNER.name,
            value: EmployeeRole.OWNER.id.toString(),
          },
        ]
      : [
          {
            label: EmployeeRole.MEMBER.name,
            value: EmployeeRole.MEMBER.id.toString(),
          },
        ];

  const isCurrentRoleOwner =
    Number(employeeRoleDropdownValue?.value) === EmployeeRole.OWNER.id;

  const handleChangeMailNotifyMode = (value: SingleValueType<OptionType>) => {
    if (value?.value === "true") {
      emailOnChange?.({
        target: { value: "" },
      } as ChangeEvent<HTMLInputElement>);
    } else {
      employeeCodeOnChange({
        target: { value: "" },
      } as ChangeEvent<HTMLInputElement>);
      password.passwordOnChange({
        target: { value: "" },
      } as ChangeEvent<HTMLInputElement>);
    }
    onChangeMailNotifyMode(value);
  };

  useEffect(() => {
    if (!isCurrentRoleOwner || !currentEmployee) return;

    setSelectedDivisions(currentEmployee.businessDivisions);
    setSelectedSections(currentEmployee.businessSections);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentRoleOwner, setSelectedDivisions, setSelectedSections]);

  return (
    <>
      <Form onSubmit={handleSubmit} className="space-y-6">
        <MultiParentsWithMultiChildrenDropdownField
          allParents={selectableDivisions}
          parentsValue={selectedDivisions}
          parentLabel="部署"
          parentsOnChange={onDivisionChange}
          parentRequired
          allChildren={optionSelectableSections}
          childrenValue={optionSelectedSections}
          childLabel="課"
          childrenOnChange={onSectionChange}
          childRequired
          isDisabled={isCurrentRoleOwner}
        />
        <LabelWithDropDownField
          labelText="職種"
          required
          name="jobCategoryId"
          options={jobCategoryOptions}
          value={jobCategoryDropdownValue}
          onChange={jobCategoryDropdownOnChange}
        />
        <LabelWithTextField
          labelText="役職"
          type="text"
          name="jobTitle"
          value={jobTitleValue}
          onChange={jobTitleOnChange}
        />
        <LabelWithDropDownField
          labelText="メール通知"
          required
          name="mailNotifyMode"
          options={selectableMailNotifyModes}
          value={isMailNotifyMode}
          onChange={handleChangeMailNotifyMode}
        />
        {isMailNotifyMode?.value === "true" ? (
          <LabelWithTextField
            labelText="メールアドレス"
            type="email"
            name="email"
            required={isMailNotifyMode?.value === "true"}
            autoComplete="off"
            value={emailValue}
            onChange={emailOnChange}
          />
        ) : (
          <>
            <div>
              <LabelWithTextField
                labelText="ログインID"
                disabled={isReadonly}
                type="text"
                name="employeeCode"
                autoComplete="off"
                required={isMailNotifyMode?.value === "false"}
                value={employeeCodeValue}
                onChange={employeeCodeOnChange}
              />
              <EmployeeCodeRuleMessage className="mt-2" />
            </div>
            <div>
              <DisplayablePasswordFiled
                label="初期パスワード"
                name="password"
                autoComplete="off"
                required={isMailNotifyMode?.value === "false"}
                value={password.passwordValue}
                onChange={password.passwordOnChange}
              />
              <PasswordRuleMessage className="mt-2" />
            </div>
          </>
        )}
        <div className="grid grid-cols-2 gap-x-8">
          <LabelWithTextField
            labelText="名前(姓)"
            type="text"
            name="lastName"
            autoComplete="off"
            disabled={isReadonly}
            required
            value={lastNameValue}
            onChange={lastNameOnChange}
          />
          <LabelWithTextField
            labelText="名前(名)"
            type="text"
            name="firstName"
            autoComplete="off"
            disabled={isReadonly}
            required
            value={firstNameValue}
            onChange={firstNameOnChange}
          />
        </div>
        <div className="grid grid-cols-2 gap-x-8">
          <LabelWithTextField
            labelText="カナ(姓)"
            type="text"
            name="katakanaLastName"
            autoComplete="off"
            disabled={isReadonly}
            value={katakanaLastNameValue}
            onChange={katakanaLastNameOnChange}
          />
          <LabelWithTextField
            labelText="カナ(名)"
            type="text"
            name="katakanaFirstName"
            autoComplete="off"
            disabled={isReadonly}
            value={katakanaFirstNameValue}
            onChange={katakanaFirstNameOnChange}
          />
        </div>
        <LabelWithDropDownField
          labelText="権限"
          required
          name="employeeRoleId"
          isDisabled={isReadonly}
          options={EmployeeRoleOptions}
          value={employeeRoleDropdownValue}
          onChange={employeeRoleDropdownOnChange}
        />
        <div className="flex items-center space-x-4">
          <FormSubmitButton
            color="primary"
            value={buttonName}
            className="w-full"
            isReadOnly={isUpdating}
          />
        </div>
      </Form>
    </>
  );
};
