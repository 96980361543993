import React, { FC, FormEvent } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { canRedirectAfterSignin } from "~/libs";

import { SignInRequest } from "~/utils";

import { useInput } from "~/hooks";

import { SignIn } from "~/components/organisms";

export const SignInIndex: FC = () => {
  const navigate = useNavigate();
  const [email] = useInput("");
  const [password] = useInput("");

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const signInParams = {
      email: email.value,
      password: password.value,
    };
    // redirect_url params があればそれを取得して、ログイン後にリダイレクトする
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrl = urlParams.get("redirect_url") || "";

    await SignInRequest({
      signInParams,
      isAdmin: false,
      onSuccess: () => {
        toast("ログインしました");
        navigate(
          canRedirectAfterSignin(redirectUrl) ? redirectUrl : "/insight_posts",
        );
      },
      onError: (err) => {
        toast.error(err.message);
      },
    });
  };

  return (
    <SignIn
      handleSubmit={handleSubmit}
      onChangeEmail={email.onChange}
      onChangePassword={password.onChange}
    />
  );
};
