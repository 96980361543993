import React from "react";

import { toast } from "react-toastify";

import {
  useApisMembersEmployeeSurveysEmployeeSurveySubmissionsDestroy,
  useApisMembersEmployeeSurveySubmissionsBookmarksCreate,
  useApisMembersEmployeeSurveySubmissionsBookmarksDestroy,
  useApisMembersEmployeeSurveySubmissionsLikesCreate,
  useApisMembersEmployeeSurveySubmissionsLikesDestroy,
  useBoolean,
  useClipboard,
  useCounter,
  useFooters,
  useProvidersCurrentEmployee,
} from "~/hooks";

import { Button } from "~/components/atoms";
import {
  EmployeeSurveyQuestionDetail,
  EmployeeSurveySubmissionsLikedEmployeesModal,
  PostFooter,
  PostHeader,
} from "~/components/organisms";

import { EmployeeSurveySubmissionType } from "~/domains";

import { Href } from "~/constants/postUrl";

type PropsType = {
  employeeSurveySubmission: EmployeeSurveySubmissionType;
  className?: string;
  openReply: (value: string) => void;
  refetch?: () => void;
  canDelete: boolean;
};

export const EmployeeSurveySubmissionDetail = ({
  employeeSurveySubmission,
  openReply,
  refetch,
  canDelete,
  className = "",
}: PropsType) => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const { clipboardToCopy } = useClipboard();
  const { isChecked: isOpen, setTrue, setFalse } = useBoolean(false);
  const {
    isChecked: liked,
    setTrue: setLiked,
    setFalse: setNotLiked,
  } = useBoolean(employeeSurveySubmission.liked);
  const {
    count: likeCount,
    increment: likeIncrement,
    decrement: likeDecrement,
  } = useCounter(
    employeeSurveySubmission.employeeSurveySubmissionStat.likesCount,
  );
  const {
    isChecked: bookmarked,
    setTrue: setBookmarked,
    setFalse: setNotBookmarked,
  } = useBoolean(employeeSurveySubmission.bookmarked);
  const {
    count: bookmarkCount,
    increment: bookmarkIncrement,
    decrement: bookmarkDecrement,
  } = useCounter(
    employeeSurveySubmission.employeeSurveySubmissionStat.bookmarksCount,
  );
  const {
    isChecked: isLikedEmployeesModalOpen,
    setTrue: setLikedEmployeesModalOpen,
    setFalse: setLikedEmployeesModalClose,
  } = useBoolean(false);

  const { mutate: likeCreateRequest } =
    useApisMembersEmployeeSurveySubmissionsLikesCreate();
  const { mutate: likeDeleteRequest } =
    useApisMembersEmployeeSurveySubmissionsLikesDestroy();

  const { mutate: bookmarkCreateRequest } =
    useApisMembersEmployeeSurveySubmissionsBookmarksCreate();
  const { mutate: bookmarkDeleteRequest } =
    useApisMembersEmployeeSurveySubmissionsBookmarksDestroy();
  const { mutate: destroyRequest } =
    useApisMembersEmployeeSurveysEmployeeSurveySubmissionsDestroy();
  const handleCreateLike = () => {
    likeCreateRequest(
      {
        employeeSurveySubmissionId: employeeSurveySubmission.id,
      },
      {
        onSuccess: () => {
          setLiked();
          likeIncrement();
        },
      },
    );
  };

  const handleDeleteLike = () => {
    likeDeleteRequest(
      {
        employeeSurveySubmissionId: employeeSurveySubmission.id,
      },
      {
        onSuccess: () => {
          setNotLiked();
          likeDecrement();
        },
      },
    );
  };

  const handleCreateBookmark = () => {
    bookmarkCreateRequest(
      {
        employeeSurveySubmissionId: employeeSurveySubmission.id,
      },
      {
        onSuccess: () => {
          setBookmarked();
          bookmarkIncrement();
        },
      },
    );
  };

  const handleDeleteBookmark = () => {
    bookmarkDeleteRequest(
      {
        employeeSurveySubmissionId: employeeSurveySubmission.id,
      },
      {
        onSuccess: () => {
          setNotBookmarked();
          bookmarkDecrement();
        },
      },
    );
  };

  const handleDelete = () => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;
    destroyRequest(
      {
        employeeSurveyId: employeeSurveySubmission.employeeSurvey.id,
        id: employeeSurveySubmission.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          refetch?.();
        },
      },
    );
  };

  const leftFooters = useFooters({
    like: {
      doneAlready: liked,
      undoOnClick: handleDeleteLike,
      doOnClick: handleCreateLike,
      count: likeCount,
      textOnClick: setLikedEmployeesModalOpen,
    },
    bookmark: {
      doneAlready: bookmarked,
      undoOnClick: handleDeleteBookmark,
      doOnClick: handleCreateBookmark,
      count: bookmarkCount,
    },
    comment: {
      count: employeeSurveySubmission.employeeSurveySubmissionStat.repliesCount,
      undoOnClick: () => openReply(employeeSurveySubmission.id),
      doOnClick: () => openReply(employeeSurveySubmission.id),
      doneAlready: Boolean(
        employeeSurveySubmission.employeeSurveySubmissionStat.repliesCount,
      ),
    },
    aiFeedback: {
      undoOnClick: () => openReply(employeeSurveySubmission.id),
      doOnClick: () => openReply(employeeSurveySubmission.id),
      doneAlready: true,
    },
  });

  const sortedEmployeeSurveyQuestions =
    employeeSurveySubmission.employeeSurvey.employeeSurveyQuestions.sort(
      (a, b) => a.displayOrder - b.displayOrder,
    );
  const questions = isOpen
    ? sortedEmployeeSurveyQuestions
    : sortedEmployeeSurveyQuestions.slice(0, 1);
  return (
    <div
      className={`flex flex-col ${className}`}
      id={employeeSurveySubmission.id}
    >
      {!employeeSurveySubmission.shareable && (
        <span className="w-fit rounded text-sm font-semibold text-center pb-1 pt-0.5 px-3 text-white bg-icon-heart">
          シェア不可
        </span>
      )}
      <PostHeader
        className={!employeeSurveySubmission.shareable ? "mt-4" : ""}
        postedEmployee={employeeSurveySubmission.employee}
        miniText={employeeSurveySubmission.postDate}
        underLabel={employeeSurveySubmission.employeeSurvey.title}
        menuItems={[
          {
            icon: "ioCopyOutline",
            text: "リンクをコピー",
            onClick: () =>
              clipboardToCopy(
                `${location.origin}${Href.EmployeeSurveySubmission(employeeSurveySubmission.id)}`,
              ),
            disabled: false,
          },
          {
            icon: "ioTrashOutline",
            text: "削除",
            onClick: () => handleDelete(),
            disabled:
              !canDelete ||
              currentEmployee?.id !== employeeSurveySubmission.employee.id,
          },
        ]}
      />
      <div className="mt-8 space-y-2">
        <div className="space-y-8">
          {questions.map((question) => (
            <EmployeeSurveyQuestionDetail
              key={question.id}
              question={question}
              options={question.employeeSurveyQuestionOptions || []}
              answers={
                employeeSurveySubmission.employeeSurveySubmissionAnswers?.map(
                  (a) => ({
                    employeeSurveyQuestionId: a.employeeSurveyQuestionId,
                    employeeSurveyQuestionOptionId:
                      a.employeeSurveyQuestionOptionId,
                    answer: a.answer,
                  }),
                ) || []
              }
            />
          ))}
        </div>
        {isOpen ? (
          <Button
            text="閉じる"
            onClick={setFalse}
            color="gray"
            outline
            className="w-full"
            size="sm"
          />
        ) : (
          <Button
            text="もっと見る"
            onClick={setTrue}
            color="gray"
            outline
            className="w-full"
            size="sm"
          />
        )}
      </div>
      <PostFooter iconWithCounters={leftFooters.footers} className="mt-3" />
      {isLikedEmployeesModalOpen && (
        <EmployeeSurveySubmissionsLikedEmployeesModal
          employeeSurveySubmissionId={employeeSurveySubmission.id}
          onClose={setLikedEmployeesModalClose}
        />
      )}
    </div>
  );
};
