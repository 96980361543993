import React, { FC, useState, FormEvent, ChangeEvent } from "react";

import { toast } from "react-toastify";

import { uniqueObjectArrayById } from "~/utils";

import {
  useBoolean,
  useEditorState,
  useClipboard,
  useApisMembersInsightPostsDestroy,
  useApisMembersInsightPostsUpdate,
  useEditorStateLinkContentGetter,
} from "~/hooks";

import { PostDetailWrapper } from "~/components/atoms";
import {
  InsightPostDetail,
  PostHeader,
  InsightPostForm,
} from "~/components/organisms";

import {
  InsightPostType,
  DropDownOnChangeType,
  OptionType,
  PostDetailMenuItemType,
  AvatarAndNameEmployeeType,
  PreviewWithFileType,
  ProvidingServiceType,
  JobCategoryPhaseType,
} from "~/domains";

import { Href } from "~/constants/postUrl";

type PropsType = {
  isHighlight?: boolean;
  insightPost: InsightPostType;
  mentions: AvatarAndNameEmployeeType[];
  selectableProvidingServices: ProvidingServiceType[];
  selectableJobCategoryPhases: JobCategoryPhaseType[];
  defaultAutoFucus?: boolean;
  className?: string;
  openComment: (insightPostId: string) => void;
  deleteItem?: () => void;
  updateItem?: (newItem: InsightPostType) => void;
};

export const InsightPostDetailWithEditForm: FC<PropsType> = ({
  insightPost,
  mentions,
  selectableProvidingServices,
  selectableJobCategoryPhases,
  defaultAutoFucus = false,
  className = "",
  isHighlight = false,
  deleteItem,
  updateItem,
  openComment,
}: PropsType) => {
  const {
    isChecked: openEditForm,
    setTrue: openEditFormTrue,
    setFalse: openEditFormFalse,
  } = useBoolean(false);
  const [files, setFiles] = useState<PreviewWithFileType[]>([]);
  const { isChecked: shareable, onChange: onChangeShareable } = useBoolean(
    insightPost.shareable,
  );
  const [editorState] = useEditorState(insightPost.content);
  const { fetchedData } = useEditorStateLinkContentGetter({
    watchEditorState: editorState.value,
    isWatch: openEditForm,
  });

  const { clipboardToCopy } = useClipboard();

  const [providingService, setProvidingService] = useState<
    OptionType | undefined
  >({
    label: insightPost.providingService?.name || "すべて",
    value: insightPost.providingService?.id || "",
  });

  const [jobCategoryPhase, setJobCategoryPhase] = useState<OptionType>({
    label: insightPost.jobCategoryPhase?.name || "すべて",
    value: insightPost.jobCategoryPhase?.id || "",
  });

  const { mutate: updateRequest, isLoading: isSubmitting } =
    useApisMembersInsightPostsUpdate();
  const { mutate: deleteRequest } = useApisMembersInsightPostsDestroy();

  const handleChangeJobCategoryPhase = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    setJobCategoryPhase({ label: e.target.name, value: e.target.value });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const body = {
      content: editorState.jsonContent,
      shareable: shareable,
      jobCategoryPhaseId: jobCategoryPhase?.value,
      providingServiceId: providingService?.value,
      attachFiles: files,
      quotePostId: fetchedData?.id,
    };
    updateRequest(
      {
        id: insightPost.id,
        body,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          updateItem?.(data.insightPost);
          openEditFormFalse();
        },
      },
    );
  };

  const deleteInsightPostRequest = () => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    deleteRequest(
      {
        id: insightPost.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          deleteItem?.();
        },
      },
    );
  };

  const detailItems: PostDetailMenuItemType[] = [
    {
      icon: "ioCopyOutline",
      text: "リンクをコピー",
      onClick: () =>
        clipboardToCopy(
          `${location.origin}${Href.InsightPost(insightPost.id)}`,
        ),
      disabled: false,
    },
    {
      icon: "ioCreateOutline",
      text: "編集する",
      onClick: () => openEditFormTrue(),
      disabled: !insightPost.editable,
    },
    {
      icon: "ioTrashOutline",
      text: "削除する",
      onClick: deleteInsightPostRequest,
      disabled: !insightPost.editable,
    },
  ];

  const cancelItems = [
    {
      text: "編集をキャンセル",
      onClick: () => openEditFormFalse(),
      disabled: !insightPost.editable,
    },
  ];

  const selectableJobCategoryPhasesWithArchive = uniqueObjectArrayById(
    insightPost.jobCategoryPhase
      ? [insightPost.jobCategoryPhase, ...selectableJobCategoryPhases]
      : selectableJobCategoryPhases,
  );

  const selectableProvidingServicesWithArchive = uniqueObjectArrayById(
    insightPost.providingService
      ? [insightPost.providingService, ...selectableProvidingServices]
      : selectableProvidingServices,
  );

  return (
    <div className={className}>
      {openEditForm ? (
        <PostDetailWrapper skipHoverStyle>
          <PostHeader
            miniText={insightPost.createdAt}
            postedEmployee={insightPost.employee}
            menuItems={cancelItems}
            underLabel={`${insightPost.jobCategory.name} - ${
              insightPost.providingService?.name || "すべて"
            } - 
            ${insightPost.jobCategoryPhase?.name || "すべて"}`}
          />
          <InsightPostForm
            isSubmitting={isSubmitting}
            defaultAutoFucus={defaultAutoFucus}
            mentions={mentions}
            files={files}
            onChangeFiles={setFiles}
            editorState={editorState.value}
            setEditorState={editorState.onChange}
            selectableProvidingServices={selectableProvidingServicesWithArchive}
            selectedProvidingService={providingService}
            setProvidingService={(newValue: DropDownOnChangeType<OptionType>) =>
              setProvidingService(newValue as OptionType)
            }
            selectableJobCategoryPhases={selectableJobCategoryPhasesWithArchive}
            selectedJobCategoryPhase={jobCategoryPhase}
            onChangeJobCategoryPhase={handleChangeJobCategoryPhase}
            shareable={shareable}
            setShareable={onChangeShareable}
            handleSubmit={handleSubmit}
            submitButtonLabel="保存する"
            className="mt-8"
            blockQuote={fetchedData}
          />
        </PostDetailWrapper>
      ) : (
        <InsightPostDetail
          insightPost={insightPost}
          menuItems={detailItems}
          openComment={() => openComment(insightPost.id)}
          isHighlight={isHighlight}
        />
      )}
    </div>
  );
};
