import React, { FC } from "react";

import { useApisManagersBackgroundJobStatusesIndex } from "~/hooks";

import { GridHeaderCells } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import { GridRow, InfiniteScrollWithMoreButton } from "~/components/molecules";

export const ManagersBackgroundJobStatusesIndex: FC = () => {
  const { data, fetchNextPage, hasNextPage, isFetching } =
    useApisManagersBackgroundJobStatusesIndex();

  const breadCrumbItems = [
    { label: "非同期Job実行状況", href: "/managers/background_job_statuses" },
    { label: "一覧", href: "/managers/background_job_statuses" },
  ];

  const backgroundJobStatuses = data?.pages.flatMap(
    (backgroundJobStatus) => backgroundJobStatus.backgroundJobStatuses,
  );

  return (
    <EmployeeLayout withRightSidebar={false} breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar={false}>
        <InfiniteScrollWithMoreButton
          itemsLength={data?.pages.length}
          nextFetchFunction={fetchNextPage}
          isLoading={isFetching}
          hasMore={hasNextPage}
          className="mt-12"
        >
          <div className="grid grid-cols-5">
            <GridHeaderCells
              texts={[
                "実行したJob",
                "実行日時",
                "ステータス",
                "終了日時",
                "エラーメッセージ",
              ]}
            />
            <>
              {backgroundJobStatuses?.map((backgroundJobStatus) => (
                <GridRow
                  key={backgroundJobStatus.id}
                  lists={[
                    backgroundJobStatus.jobClassName,
                    backgroundJobStatus.createdAt,
                    backgroundJobStatus.status,
                    backgroundJobStatus.finishedAt,
                    backgroundJobStatus.errorMessage,
                  ]}
                />
              ))}
            </>
          </div>
        </InfiniteScrollWithMoreButton>
      </EmployeeLayoutMainContent>
    </EmployeeLayout>
  );
};
