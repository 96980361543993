import { AvatarAndNameEmployeeType, EmployeeRoleType } from "~/domains";

export type DeliveryEventNotificationHistoryType = {
  id: string;
  jaPostableTypeName: string;
  title: string;
  postableType: DeliveryEventNotificationHistoryPostableType;
  postableId: string;
  sender: AvatarAndNameEmployeeType;
  employeeRole: EmployeeRoleType;
  readAt?: string;
  createdAt: string;
  updatedAt: string;
};

export type DeliveryEventNotificationHistoryPostableType =
  keyof typeof DeliveryEventNotificationHistoryPostableTypeColorMapping;

export const DeliveryEventNotificationHistoryPostableTypeColorMapping = {
  InsightPost: {
    className: "border-4 border-solid border-icon-bookmark",
    link: "/insight_posts",
  },
  FocusChallenge: {
    className: "border-4 border-solid border-icon-bookmark",
    link: null,
  },
  KaizenIdea: {
    className: "border-4 border-solid border-icon-bookmark",
    link: "/kaizen_ideas",
  },
  InsightPostSharedEmployee: {
    className: "border-4 border-solid border-icon-share",
    link: "/insight_posts",
  },
  KnowledgeInsightPost: {
    className: "border-4 border-solid border-icon-share",
    link: "/knowledge_insight_posts",
  },
  InsightPostObjective: {
    className: "border-4 border-solid border-primary-600",
    link: null,
  },
  KpiTerm: {
    className: "border-4 border-solid border-primary-600",
    link: null,
  },
  SkillEvaluationTerm: {
    className: "border-4 border-solid border-primary-600",
    link: null,
  },
  SkillEvaluationStandardSummaryCategory: {
    className: "border-4 border-solid border-primary-600",
    link: null,
  },
  DocumentFileCategory: {
    className: "border-4 border-solid border-primary-600",
    link: null,
  },
  SalesConceptCategory: {
    className: "border-4 border-solid border-primary-600",
    link: null,
  },
  QuestionAnswerCategory: {
    className: "border-4 border-solid border-primary-600",
    link: null,
  },
  EmployeeSurveySubmission: {
    className: "border-4 border-solid border-primary-600",
    link: null,
  },
} as const;
