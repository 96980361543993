import React, { MouseEvent, FC } from "react";

import { Button, FormSubmitButton } from "~/components/atoms";

type PropsType = {
  isSubmitting?: boolean;
  className?: string;
  isReadOnly?: boolean;
  children?: React.ReactNode;
  handleNotReadonly: (e: MouseEvent<HTMLButtonElement>) => void;
  handleReadonly: (e: MouseEvent<HTMLButtonElement>) => void;
  handleRemove: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const EditAndFormSubmitButton: FC<PropsType> = ({
  isSubmitting = false,
  isReadOnly,
  className = "",
  children,
  handleNotReadonly,
  handleReadonly,
  handleRemove,
}: PropsType) => {
  return (
    <div
      className={`flex items-center space-x-4 justify-end w-full ${className}`}
    >
      {isReadOnly ? (
        <>
          <Button text="削除" onClick={handleRemove} color="gray" outline />
          <Button
            text="編集"
            onClick={handleNotReadonly}
            color="gray"
            outline
          />
          {children}
        </>
      ) : (
        <>
          <Button
            text="キャンセル"
            onClick={handleReadonly}
            color="gray"
            outline
          />
          <FormSubmitButton
            value="保存"
            color="primary"
            isReadOnly={isSubmitting}
          />
        </>
      )}
    </div>
  );
};
