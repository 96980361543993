import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ResponseMessageType } from "~/domains";

type RequestDataType = {
  employeeId: string;
  body: {
    password: string;
    passwordConfirmation: string;
  };
};

const request = ({ employeeId, body }: RequestDataType) => {
  return new ApiClient()
    .put<ResponseMessageType>(
      `/api/managers/employees/${employeeId}/change_passwords`,
      {
        ...body,
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersEmployeesChangePasswordsUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ResponseMessageType, RequestDataType> => {
  return useMutation(request, { ...options });
};
