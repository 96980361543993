import React, { FC, ReactNode, useEffect, useState } from "react";

import { Helmet } from "react-helmet-async";

import Default from "~/assets/images/logos/favicon.png";
import MentionNotifyFavicon from "~/assets/images/logos/mention_notify_favicon.png";
import NotifyFavicon from "~/assets/images/logos/notify_favicon.png";

import { pagePathUtils } from "~/utils";

import {
  useBoolean,
  useProvidersEmployeeLayout,
  MenuType,
  useApisMembersDeliveryNotificationsUpdate,
  useIntervalFetchNotificationCountsIndex,
} from "~/hooks";

import { ModalWrapper } from "~/components/atoms";
import {
  Footer,
  Header,
  Sidebar,
  SidebarCurrentMenu,
} from "~/components/organisms";

import { urlReadMutateMapping } from "./urlReadMutateMapping";

import { BreadCrumbItemType } from "~/domains";

type Props = {
  breadCrumbItems: BreadCrumbItemType[];
  className?: string;
  children?: ReactNode;
  withRightSidebar?: boolean;
};

const ModalTitle = {
  Home: "通知一覧",
  Notifications: "メンション一覧",
  Heart: "いいね一覧",
  ChatbubbleEllipses: "リプライ一覧",
  Mail: "DMユーザー一覧",
  Bookmark: "ブックマークフォルダ一覧",
};

export const EmployeeLayout: FC<Props> = ({
  breadCrumbItems,
  children,
  withRightSidebar = false,
  className = "",
}: Props) => {
  const { isRightSidebarMain } = useProvidersEmployeeLayout();
  const unreadFetchData = useIntervalFetchNotificationCountsIndex();
  const { mutate: readMutate } = useApisMembersDeliveryNotificationsUpdate();

  const isBookmarkPage = pagePathUtils.isStartPathPage("bookmark");
  const isDMPage = pagePathUtils.isStartPathPage("employees");
  const defaultMenu = () => {
    if (isBookmarkPage) return "Bookmark";
    if (isDMPage) return "Mail";
    return "Home";
  };
  const [currentMenu, setCurrentMenu] = useState<MenuType>(defaultMenu());

  const {
    isChecked: isModalOpen,
    setFalse: onClose,
    setTrue: onOpen,
  } = useBoolean(false);

  const handleCurrentMenu = (menu: MenuType) => {
    setCurrentMenu(menu);
    menu === "Home" ? onClose() : onOpen();
  };

  const renderFavicon = () => {
    if (unreadFetchData?.mentionNotificationCount) {
      return MentionNotifyFavicon as string;
    } else if (
      unreadFetchData?.likeNotificationCount ||
      unreadFetchData?.deliveryNotifications.totalCount
    ) {
      return NotifyFavicon as string;
    } else {
      return Default as string;
    }
  };

  useEffect(() => {
    isModalOpen && onClose();

    const body = urlReadMutateMapping[location.pathname];
    body && readMutate({ body });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readMutate, onClose, location.pathname]);

  return (
    <div className="flex flex-col min-h-screen">
      <Helmet defer={false}>
        <link rel="icon" type="image/png" href={renderFavicon()} />
      </Helmet>
      <Header
        withRightSidebar={withRightSidebar}
        breadCrumbItems={breadCrumbItems}
        unreadNotification={unreadFetchData}
      />
      <div className="flex grow">
        <Sidebar
          className="miniTablet:w-[20%]"
          unreadNotification={unreadFetchData}
          currentMenu={currentMenu}
          breadCrumbItems={breadCrumbItems}
          setCurrentMenu={setCurrentMenu}
        />
        <div
          className={`flex ${
            withRightSidebar
              ? isRightSidebarMain
                ? "[&>:first-child]:hidden miniTablet:[&>:first-child]:block"
                : "[&>:last-child]:hidden miniTablet:[&>:last-child]:block"
              : ""
          } w-full miniTablet:w-[80%] ${
            withRightSidebar ? "border-r border-secondary-200" : ""
          } ${className}`}
        >
          {children}
        </div>
        {isModalOpen && (
          <ModalWrapper
            onClose={onClose}
            headerTitle={ModalTitle[currentMenu]}
            className="miniTablet:hidden"
            wrapperClassName="miniTablet:hidden"
          >
            <div className="mt-4">
              <SidebarCurrentMenu
                menu={currentMenu}
                breadCrumbItems={breadCrumbItems}
                unreadNotification={unreadFetchData}
              />
            </div>
          </ModalWrapper>
        )}
      </div>
      <Footer
        unreadNotification={unreadFetchData}
        currentMenu={currentMenu}
        setCurrentMenu={handleCurrentMenu}
      />
    </div>
  );
};
