import React, { FC, FormEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useInput,
  useApisManagersEmployeesChangePasswordsUpdate,
  useApisManagersEmployeesEdit,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { SettingPassword } from "~/components/organisms";

export const ManagersEmployeesChangePasswordsShow: FC = () => {
  const { employeeId = "" } = useParams<{ employeeId: string }>();
  const navigate = useNavigate();

  const { data } = useApisManagersEmployeesEdit({
    id: employeeId,
  });

  const [{ value: password, onChange: onChangePassword }] = useInput("");
  const [
    { value: passwordConfirmation, onChange: onChangePasswordConfirmation },
  ] = useInput("");

  const { mutate, isLoading } = useApisManagersEmployeesChangePasswordsUpdate();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(
      {
        employeeId: employeeId,
        body: {
          password,
          passwordConfirmation,
        },
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          navigate("/managers/employees");
        },
      },
    );
  };

  const breadCrumbItems = [
    { label: "ユーザー設定", href: "/managers/employees" },
    {
      label: "パスワード変更",
      href: `/managers/employees/${employeeId}/change_passwords`,
    },
  ];

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent withRightSidebar>
          {data && (
            <h2 className="text-lg text-center text-gray-600">
              {data.lastName} {data.firstName}
            </h2>
          )}
          <SettingPassword
            onChangePassword={onChangePassword}
            onChangePasswordConfirmation={onChangePasswordConfirmation}
            isLoading={isLoading}
            onSubmit={handleSubmit}
            className="mt-8"
          />
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar />
      </EmployeeLayout>
    </>
  );
};
