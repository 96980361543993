import React, { FC } from "react";

type PropsType = {
  blockProps: {
    src: string;
  };
};

export const EditorImage: FC<PropsType> = ({ blockProps }: PropsType) => {
  return (
    <img src={blockProps.src} className="mx-auto max-w-ful" alt="editor" />
  );
};
