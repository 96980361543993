import React, { FC, ReactNode, useState, createContext } from "react";
import { useNavigate } from "react-router-dom";

import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";

import { canRedirectAfterSignin } from "~/libs";

import { useApisMembersProfilesShow } from "~/hooks";

import { Loading } from "~/components/molecules";

import {
  CompanyContractPlanType,
  EmployeeProfileType,
  EmployeeRoleType,
} from "~/domains";

export const CurrentEmployeeContext = createContext<{
  currentEmployee?: EmployeeProfileType;
  refetch: () => void;
}>({
  currentEmployee: undefined,
  refetch: () => "",
});

type PropsType = {
  accessAbleRole: EmployeeRoleType[];
  accessAblePlan: CompanyContractPlanType[];
  children: ReactNode;
};

export const CurrentEmployeeProvider: FC<PropsType> = ({
  accessAbleRole,
  accessAblePlan,
  children,
}: PropsType) => {
  const navigate = useNavigate();
  const [currentEmployee, setCurrentEmployee] = useState<EmployeeProfileType>();
  const onError = (error: unknown) => {
    Sentry.captureException(error);
    error instanceof Error && toast.error(error.message);
    const redirectURL = canRedirectAfterSignin(location.pathname)
      ? `/sign_in?redirect_url=${location.pathname}`
      : "/sign_in";
    navigate(redirectURL);
  };

  const onSuccess = (data: EmployeeProfileType) => {
    setCurrentEmployee(data);

    const accessAble =
      accessAbleRole.some((role) => role.id === data.employeeRole.id) &&
      accessAblePlan.some((plan) => plan.id === data.company.contractPlan.id);

    Sentry.getCurrentScope().setUser({
      id: data.id,
      company: data.company,
      employeeRole: data.employeeRole,
      name: `${data.lastName} ${data.firstName}`,
      katakanaName: `${data.katakanaLastName} ${data.katakanaFirstName}`,
      jobCategory: data.jobCategory,
      jobTitle: data.jobTitle,
      employeeCode: data.employeeCode,
      host: window.location.host,
    });
    if (!accessAble) {
      const error = new Error("アクセス権限がありません");
      Sentry.captureException(error);
      toast.error(error.message);
      navigate("/");
    }
  };

  const { refetch } = useApisMembersProfilesShow({
    config: {
      onSuccess,
      onError,
    },
  });

  if (!currentEmployee) return <Loading className="mt-36" />;

  return (
    <CurrentEmployeeContext.Provider value={{ currentEmployee, refetch }}>
      {children}
    </CurrentEmployeeContext.Provider>
  );
};
