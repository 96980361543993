import React, { FC, useState } from "react";
import { useParams } from "react-router-dom";

import {
  useApisManagersSkillEvaluationTermsProgressesShow,
  useMultipleDropdown,
  useProvidersCurrentEmployee,
} from "~/hooks";

import { TextField } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  LabelWithMultipleDropdownField,
  RangeDatePicker,
} from "~/components/molecules";
import { NotFoundIndex } from "~/components/pages";

import { SkillEvaluationTermType } from "~/domains";

export const ManagersSkillEvaluationTermsProgressShow: FC = () => {
  const { currentEmployee } = useProvidersCurrentEmployee();

  const { id = "" } = useParams<{ id: string }>();
  const breadCrumbItems = [
    { label: "スキルチェック", href: "/managers/skill_evaluation_terms" },
    {
      label: "進捗一覧",
      href: `/managers/skill_evaluation_terms/${id}/progress`,
    },
  ];
  const [dropdownBusinessSections] = useMultipleDropdown({
    selectableValue: currentEmployee?.businessSections || [],
  });
  const [skillEvaluationTerm, setSkillEvaluationTerm] =
    useState<SkillEvaluationTermType>();

  console.log({ dropdownBusinessSections });
  const { data, isError } = useApisManagersSkillEvaluationTermsProgressesShow({
    skillEvaluationTermId: id,
    params: {
      businessSectionIds: dropdownBusinessSections?.value?.map((v) => v.id),
    },
    config: {
      onSuccess: (data) => {
        dropdownBusinessSections.setSelectableOption(
          data.selectableBusinessSections,
        );
        setSkillEvaluationTerm(data.skillEvaluationTerm);
      },
    },
  });

  console.log("data.results", data?.results);

  if (isError) return <NotFoundIndex />;

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        {skillEvaluationTerm && (
          <div className="space-y-6">
            <RangeDatePicker
              readOnly
              startDate={new Date(skillEvaluationTerm.fromDate)}
              endDate={new Date(skillEvaluationTerm.toDate)}
            />
            <TextField
              type="text"
              name="title"
              disabled
              value={skillEvaluationTerm.title}
              className="text-center font-semibold"
            />
          </div>
        )}
        <LabelWithMultipleDropdownField
          labelText="課"
          required
          name="businessSections"
          className="mt-6"
          options={dropdownBusinessSections?.dropdownSelectableValue}
          value={dropdownBusinessSections?.dropdownValue}
          onChange={dropdownBusinessSections?.onChange}
        />
        {data && (
          <div className="mt-6 space-y-6">
            {data.results.map((result, index) => (
              <div
                key={`businessSection-${result.businessSection.id}-${index}`}
                className="space-y-4"
              >
                <TextField
                  type="text"
                  name={`businessSection-${result.businessSection.id}`}
                  disabled
                  value={result.businessSection.name}
                  className="text-center font-semibold"
                />
                {result.skillEvaluationTermResults.map(
                  (resultPoint, resultIndex) => (
                    <div
                      key={`skill-${resultPoint.skillEvaluationTerm.id}-${resultPoint.businessSection.id}-${resultPoint.reviewee.id}-${resultIndex}`}
                    >
                      <table className="w-full table-auto">
                        <thead>
                          <tr>
                            <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 bg-gray-100 border-b">
                              指名
                            </th>
                            <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 bg-gray-100 border-b">
                              ステータス
                            </th>
                            <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 bg-gray-100 border-b">
                              チェック
                            </th>
                            <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 bg-gray-100 border-b">
                              評価
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="px-4 py-2 text-sm text-gray-600 border-b">
                              {resultPoint.reviewee.name}
                            </td>
                            <td className="px-4 py-2 text-sm text-gray-600 border-b">
                              {skillEvaluationTerm?.publicStatus.name}
                            </td>
                            <td className="px-4 py-2 text-sm text-gray-600 border-b">
                              {resultPoint.revieweeSumPoint}
                            </td>
                            <td className="px-4 py-2 text-sm text-gray-600 border-b">
                              {resultPoint.reviewerSumPoint}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ),
                )}
              </div>
            ))}
          </div>
        )}
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
