import React, { FC, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { pagePathUtils, SignOut } from "~/utils";

import {
  MenuType,
  useBoolean,
  useMenus,
  useProvidersCurrentEmployee,
} from "~/hooks";

import { Avatar, ButtonWithIcon, Link, ModalWrapper } from "~/components/atoms";
import { FloatingChatBox } from "~/components/organisms";

import { SidebarCurrentMenu } from "./SidebarMenu/SidebarCurrentMenu";
import { SidebarMenu } from "./SidebarMenu/SidebarMenu";

import {
  AnalyticsPlanType,
  ApiMembersUnreadNotificationCountsType,
  BreadCrumbItemType,
  ManagerOrOwnerRoles,
} from "~/domains";

type PropsType = {
  className?: string;
  unreadNotification?: ApiMembersUnreadNotificationCountsType;
  currentMenu: MenuType;
  breadCrumbItems: BreadCrumbItemType[];
  setCurrentMenu: (menu: MenuType) => void;
};

export const Sidebar: FC<PropsType> = ({
  className = "",
  unreadNotification,
  currentMenu,
  breadCrumbItems,
  setCurrentMenu,
}: PropsType) => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const isManagerPage = pagePathUtils.isManagerPage();
  const { menus } = useMenus();
  const {
    isChecked: isAvatarPopupOpen,
    setTrue: setAvatarPopupOpen,
    setFalse: setAvatarPopupClose,
  } = useBoolean(false);
  const {
    isChecked: isChatOpen,
    setTrue: openChat,
    setFalse: closeChat,
  } = useBoolean(false);

  const handleOpenChat = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    openChat();
  };

  const isManagerOrOwner = ManagerOrOwnerRoles.some(
    (role) => role.id === currentEmployee?.employeeRole.id,
  );
  const isAnalyticsPlan =
    currentEmployee?.company.contractPlan.id === AnalyticsPlanType.id;
  const navigate = useNavigate();
  const onClickSignOut = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const result = confirm("本当にログアウトしますか？");
    if (!result) return;

    await SignOut({
      isAdmin: false,
      onSuccess: () => {
        toast("ログアウトしました");
        navigate("/sign_in");
      },
      onError: (err) => {
        toast.error(err.message);
      },
    });
  };

  return (
    <div
      className={`sticky z-sidebar top-header-offset h-full hidden miniTablet:flex min-w-[250px] items-start ${className}`}
    >
      <aside className="flex flex-col">
        {menus.map((menu) => (
          <SidebarMenu
            key={menu.type}
            menuType={menu.type}
            href={menu.href}
            unreadNotification={unreadNotification}
            selectMenu={setCurrentMenu}
            currentMenuType={currentMenu}
          />
        ))}
        {currentEmployee && (
          <div className="relative mt-16 flex flex-col mx-auto max-w-full">
            <ButtonWithIcon
              onClick={handleOpenChat}
              icon={{
                icon: "ioChatboxOutline",
                size: "1.5rem",
                color: "text-white",
              }}
              className="bg-primary-600 rounded-full p-2"
              srOnlyText="Chatを開く"
            />
            <button
              onClick={setAvatarPopupOpen}
              type="button"
              className="text-center mt-4"
            >
              <Avatar src={currentEmployee.avatarUrl} size="sm" withRing />
            </button>
            {isChatOpen && <FloatingChatBox onClose={closeChat} />}
            {isAvatarPopupOpen && (
              <>
                <ModalWrapper isWhite onClose={setAvatarPopupClose} />
                <div className="absolute left-full bottom-2 ml-2 w-max z-modal shadow p-3.5 text-left rounded bg-white border border-solid border-secondary-400 text-secondary-600">
                  <Link
                    to={isManagerPage ? "/managers/profile" : "/profile"}
                    className="block hover:bg-secondary-100 py-1.5 px-1 rounded"
                  >
                    マイページ
                  </Link>
                  <Link
                    to="/"
                    color="primary"
                    className="block hover:bg-secondary-100 py-1.5 px-1 rounded"
                  >
                    メンバーページ
                  </Link>
                  {isManagerOrOwner && (
                    <>
                      <Link
                        to="/managers"
                        color="primary"
                        className="block hover:bg-secondary-100 py-1.5 px-1 rounded"
                      >
                        管理者ページ
                      </Link>
                      {isAnalyticsPlan && (
                        <Link
                          to="/analytics_plans"
                          color="primary"
                          className="block hover:bg-secondary-100 py-1.5 px-1 rounded"
                        >
                          アナリティクスページ
                        </Link>
                      )}
                    </>
                  )}
                  <button
                    type="button"
                    onClick={onClickSignOut}
                    className="block w-full text-left hover:bg-secondary-100 py-1.5 px-1 rounded"
                  >
                    ログアウト
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </aside>
      <div className="w-full bg-primary-200 hidden-scrollbar miniTablet-screen-height overflow-auto py-6">
        <SidebarCurrentMenu
          menu={currentMenu}
          breadCrumbItems={breadCrumbItems}
          unreadNotification={unreadNotification}
        />
      </div>
    </div>
  );
};
